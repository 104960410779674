import { ControlledFilterBar, Div, ExportPDFDrawer } from '@/components'
import { useFormContext, useWatch } from 'react-hook-form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import React, { useMemo } from 'react'
import { usePdfTemplates } from '@/libs/react-query/hooks/usePdfTemplates'
import { Tag, TagProps } from '@punto-ui/react'
import { StarIcon } from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { useHandleCreatePdf } from '@/libs/react-query/mutations/reports/useHandleCreatePdf'
import { getPdfVariablesFromLayouts } from '@/components/Drawers/ExportPDF/variable'
import { capitalizeFirstLetter } from '@/utils/workers/name'
import { useHandleDeletePdf } from '@/libs/react-query/mutations/reports/useHandleDeletePdf'
import { IReportsStateSchema } from '../reports-state'
import { TableList } from '../components/TableList'
import { useHandleToggleFavoritePdf } from '@/libs/react-query/mutations/reports/useHandleToggleFavoritePdf'

export const PdfHub = () => {
  const [selectedTemplateId, setSelectedTemplateId] = React.useState('')
  const reportStateMethods = useFormContext<IReportsStateSchema>()

  const { addTab, setActiveTab } = useTabStore((state) => ({
    setActiveTab: state.setActiveTab,
    addTab: state.addTab,
  }))

  const isFavoriteFilter = useWatch({
    control: reportStateMethods.control,
    name: 'filterFavorites',
  })

  const { data: templates, isFetching: isLoadingTemplates } = usePdfTemplates({
    name: '',
    page: 0,
    perPage: 100,
  })

  const formattedPdfs = useMemo(() => {
    return (
      templates?.data
        .map((template) => {
          const variables = getPdfVariablesFromLayouts(template.layouts)
          const variableTypesDupped = variables
            .filter((variable) => {
              const hasDot = variable.includes('.')
              const [variableName] = variable.split('.')
              return hasDot && variableName
            })
            .map((variable) => capitalizeFirstLetter(variable.split('.')[0]))
          const variableTypes = [...(new Set(variableTypesDupped) as any)]

          return {
            id: template.id,
            name: template.name,
            isFavorite: template.favorite,
            isRemovable: !!template.identifier_id,
            isEditable: !!template.identifier_id,
            tagInName:
              template.identifier_id === null ? (
                <Tag variant="warning">Reporte Fijo</Tag>
              ) : null,
            tag: (
              <Div
                css={{
                  display: 'flex',
                  gap: '$2',
                }}
              >
                {variableTypes.map((v) => (
                  <Tag key={v} variant={tagColorByType[v] || 'positive'}>
                    {v}
                  </Tag>
                ))}
              </Div>
            ),
            canDuplicate: true,
            isDownloadable: true,
          }
        })
        .filter((d) => {
          if (isFavoriteFilter) {
            return d.isFavorite
          }
          return true
        })
        .sort((a, b) => a.name.localeCompare(b.name)) || []
    )
  }, [isFavoriteFilter, templates])

  const { mutateAsync: handleCreatePdf, isLoading: isCreatingPdf } =
    useHandleCreatePdf()
  const { mutateAsync: handleDeletePdf, isLoading: isDeletingPdf } =
    useHandleDeletePdf()
  const {
    mutateAsync: handleToggleFavorite,
    isLoading: isLoadingToggleFavorite,
  } = useHandleToggleFavoritePdf()

  const handleDuplicate = async (id: string) => {
    const template = templates?.data.find((t) => t.id === id)
    if (!template) return

    await handleCreatePdf({
      name: `${template.name} - Cópia`,
      description: template.description,
      direction: template.direction,
      favorite: template.favorite,
      size: template.size,
      has_two_per_page: !!template.has_two_per_page,
      layouts: template.layouts.map((layout) => {
        return {
          numberOfColumns: layout.number_of_columns,
          order: layout.order,
          content: layout.content.map((c) => {
            return {
              type: c.type,
              width: c.width || undefined,
              order: c.order || undefined,

              height: c.height || undefined,

              value: c.value || undefined,
              color: c.color || undefined,
              is_bold: c.is_bold || undefined,
              is_italic: c.is_italic || undefined,
              is_underline: c.is_underline || undefined,
              text_alignment: c.text_alignment || undefined,
              font_size: c.font_size || undefined,

              bordered: c.bordered || undefined,
              border_color: c.borderColor || undefined,
            }
          }),
        }
      }),
    })
  }

  const handleDelete = async (id: string) => {
    await handleDeletePdf(id)
  }

  const handleEdit = async (id: string) => {
    const template = templates?.data.find((t) => t.id === id)
    if (!template) return

    const existingTab = {
      id: template.id,
      type: 'add-pdf',
      label: `Editar ${template.name}`,
    }
    addTab('reportsV2', [existingTab])
    setActiveTab('reportsV2', existingTab.id)
  }

  return (
    <>
      <Div
        css={{
          height: 'calc(100vh - 60px)',
        }}
      >
        <ControlledFilterBar
          policiesIds={permissionsArray.filter((p) => p.includes('reports'))}
          isLoading={
            isLoadingTemplates ||
            isCreatingPdf ||
            isDeletingPdf ||
            isLoadingToggleFavorite
          }
          // nameFilterName="configuration.name"
          booleanFilters={[
            {
              label: 'Favoritos',
              value: 'favorite',
              name: 'filterFavorites',
              icon: <StarIcon />,
            },
          ]}
        />
        <Div
          css={{
            padding: '$4',
          }}
        >
          <Div
            css={{
              maxHeight: 'calc(100vh - 132px)',
              overflowY: 'auto',
            }}
          >
            <TableList
              handleGenerate={(id: string) => setSelectedTemplateId(id)}
              handleDuplicate={handleDuplicate}
              handleDelete={handleDelete}
              name="Recibos del Sistema"
              handleFavorite={(id: string) => handleToggleFavorite(id)}
              tables={formattedPdfs}
              handleEdit={handleEdit}
            />
          </Div>
        </Div>
      </Div>
      <ExportPDFDrawer
        pdfId={selectedTemplateId}
        isOpen={!!selectedTemplateId}
        setIsOpen={() => setSelectedTemplateId('')}
      />
    </>
  )
}

const tagColorByType: Record<string, TagProps['variant']> = {
  Turno: 'warning',
  Colaborador: 'positive',
  Vacaciones: 'neutral',
  Movimiento: 'neutral',
  Pago: 'neutral',
  Liquidacion: 'negative',
  Nomina: 'positive',
}
