import { Div, FormRenderer } from '@/components'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import {
  CompanyInfosSchema,
  CompanyInfosSchemaField,
  CompanyInfosSchemaType,
} from './types'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import Image from 'next/image'
import { Button, styled, Text } from '@punto-ui/react'
import { useHandleUpdateCompany } from '@/libs/react-query/mutations/company/useHandleUpdateCompany'
import useToast from '@/hooks/useToast'
import { useMemo } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { useHandleUpdateCompanyProfilePicture } from '@/libs/react-query/mutations/company/useHandleUpdateCompanyProfilePicture'
import CompanyBankAccount from './CompanyBankAccount'

export const CompanyInfos = () => {
  const { data: company } = useMyCompany()
  const {
    mutateAsync: handleUpdateCompany,
    isLoading: isLoadingUpdateCompany,
  } = useHandleUpdateCompany()
  const {
    mutateAsync: handleUpdateProfilePicture,
    isLoading: isLoadingUpdateProfilePicture,
  } = useHandleUpdateCompanyProfilePicture()
  const toast = useToast()

  const form = useForm<CompanyInfosSchemaType>({
    resolver: zodResolver(CompanyInfosSchema),
    defaultValues: {
      business_name: company?.bussiness_name,
      name: company?.name,
      ips: company?.IPS_patronal_number || '',
      mtss: company?.MTSS_patronal_number || '',
      document: company?.document || '',
      phone: company?.phone || '',
      email: company?.email || '',
    },
  })

  const inputForm: CompanyInfosSchemaField[] = [
    {
      _type: 'file',
      label: 'Selecionar foto da empresa',
      name: 'profile_photo',
      accept: 'image/*',
    },
    {
      _type: 'input',
      name: 'name',
      label: 'Nombre de la empresa',
    },
    {
      _type: 'input',
      name: 'business_name',
      label: 'Nombre de legal la empresa',
    },
    {
      _type: 'input',
      name: 'document',
      label: 'RUC',
    },
    {
      _type: 'row',
      numberOfColumns: 0,
      fields: [
        {
          _type: 'input',
          name: 'mtss',
          label: 'Numero MTSS patronal (Opcional)',
        },
        {
          _type: 'input',
          name: 'ips',
          label: 'Numero IPS patronal (Opcional)',
        },
      ],
    },
    {
      _type: 'input',
      name: 'phone',
      label: 'Telefono (Opcional)',
    },
    {
      _type: 'input',
      name: 'email',
      label: 'E-mail (Opcional)',
    },
  ]

  const submitCompanyUpdate = async (data: CompanyInfosSchemaType) => {
    try {
      await handleUpdateCompany({
        business_name: data.business_name,
        name: data.name,
        document: data.document,
        MTSS_patronal_number: data.mtss,
        IPS_patronal_number: data.ips,
        email: data.email,
        phone: data.phone,
      })

      if (data.profile_photo) {
        await handleUpdateProfilePicture({
          photo: data.profile_photo,
        })
      }

      toast.addToast({
        title: 'Datos actualizados con éxito',
        type: 'positive',
        description: 'Los datos de la empresa han sido actualizados con éxito',
        id: Date.now(),
      })
    } catch (err) {
      toast.addToast({
        title: 'Error al actualizar datos',
        type: 'negative',
        description:
          'Ha ocurrido un error al actualizar los datos de la empresa',
        id: Date.now(),
      })
    }
  }

  const profilePhoto = useWatch({
    control: form.control,
    name: 'profile_photo',
  })

  const previewUrl = useMemo(() => {
    if (profilePhoto) {
      return URL.createObjectURL(profilePhoto)
    } else {
      return company?.companyPictureUrl
    }
  }, [profilePhoto, company])

  return (
    <Div css={{ display: 'flex', alignItems: 'flex-start' }}>
      <FormProvider {...form}>
        <Div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              background: '$interface_light_pure',
              minWidth: 360,
              padding: '$4',
              margin: '$4',
              borderRadius: '$md',
              gap: '$4',
            }}
          >
            <Text variant="subtitle2" css={{ color: '$brand_primary_pure' }}>
              Informaciones basicas
            </Text>
            <Div
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: '$4',
              }}
            >
              {!!previewUrl && (
                <CompanyProfilePreview
                  alt="company-profile"
                  src={previewUrl}
                  width={200}
                  height={200}
                />
              )}
            </Div>
            <FormRenderer items={inputForm} />
            <Button
              isLoading={
                isLoadingUpdateCompany || isLoadingUpdateProfilePicture
              }
              onClick={() => {
                form.handleSubmit(submitCompanyUpdate)()
              }}
              icon={<CheckCircleIcon />}
            >
              Actualizar datos
            </Button>
          </Div>
        </Div>
      </FormProvider>

      <CompanyBankAccount />
    </Div>
  )
}

const CompanyProfilePreview = styled(Image, {
  height: 200,
  width: 200,
  borderRadius: '$full',
  border: '2px solid',
  objectFit: 'cover',
  objectPosition: 'center',
  borderColor: '$brand_primary_pure',
  boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
})
