import { ControlledFilterBar, Div, SmartTable } from '@/components'
import { useAvailableCells, useVacations } from '@/libs/react-query/hooks'
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { SmartColumnType } from '@/components/SmartTable/types'
import { useMemo, useEffect, useState } from 'react'
import { VacationListTableType } from './types'
import { parseVacationsToVacationsTable } from './parser'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { DialogContainer, Menu } from '@punto-ui/react'
import {
  DocumentTextIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { useTabStore } from '@/store'
import { queryClient } from '@/libs/react-query'
import { fetchVacation } from '@/libs/react-query/hooks/useVacations/useVacation'
import { SimpleDialog } from '@/components/Dialogs/SimpleDialogs'
import { useHandleDeleteVacations } from '@/libs/react-query/mutations/vacations/useHandleDeleteVacations'
import { useCan } from '@/hooks'
import { useExportReceiptModal } from '@/components/ExportReceipt'
import { useSmartTableColumnManipulation } from '@/components/SmartTable/hooks/useSmartTableColumnManipulation'
import { defaultCreateVacationValues, VacationFormType } from '../../types'

export const VacationsList = () => {
  const { handleOpenExportReceiptModal } = useExportReceiptModal()

  const creationMethods = useFormContext<VacationFormType>()

  const canEdit = useCan(['vacation.edit', 'vacation.all'])
  const canExport = useCan(['reports.all'])
  const [confirmExclusionId, setConfirmExclusionId] = useState('')

  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('vacation')),
  )
  const { addTab, setActiveTab } = useTabStore((state) => ({
    setActiveTab: state.setActiveTab,
    addTab: state.addTab,
  }))

  const methods = useForm<VacationListTableType>({
    defaultValues: {
      data: [],
      pagination: {
        page: 0,
        perPage: 25,
      },
      filters: {
        cellsIds: availableCells?.map((c) => c.id) || [],
        name: '',
      },
    },
  })

  useEffect(() => {
    if (availableCells?.length) {
      methods.setValue(
        'filters.cellsIds',
        availableCells?.map((c) => c.id) || [],
      )
    }
  }, [availableCells, methods])

  const [cellsIds, name, pagination] = useWatch({
    control: methods.control,
    name: ['filters.cellsIds', 'filters.name', 'pagination'],
  })

  const { data: vacations, isFetching: isFetchingVacations } = useVacations({
    cellsIds,
    page: pagination.page,
    perPage: pagination.perPage,
  })

  const {
    mutateAsync: handleDeleteVacations,
    isLoading: isLoadingDeleteVacations,
  } = useHandleDeleteVacations()

  useEffect(() => {
    const { data } = methods.getValues()

    if (!vacations?.data?.length) {
      return
    }

    const savedSelectedIds = data
      .filter((x) => x.checked.value)
      .map((x) => x.id)

    const vacationsParsed = parseVacationsToVacationsTable(
      vacations.data,
      savedSelectedIds,
    )

    methods.reset({
      data: vacationsParsed,
      filters: {
        cellsIds,
        name,
      },
      pagination: {
        page: vacations.page,
        perPage: vacations.pageSize,
        total: vacations.total,
        totalPages: vacations.totalPages,
      },
    })
  }, [methods, vacations])

  const columns: SmartColumnType[] = [
    {
      type: 'checkbox',
      name: 'checked',
      draggable: null,
      header: '',
      width: 24,
    },
    {
      type: 'avatar',
      name: 'avatar',
      draggable: null,
      header: '',
      width: 32,
    },
    {
      type: 'input-text',
      name: 'title',
      draggable: null,
      header: 'Titulo',
      disabled: true,
      inputType: 'no-mask',
      width: 200,
    },
    {
      type: 'input-text',
      name: 'name',
      draggable: null,
      header: 'Nombre',
      disabled: true,
      inputType: 'no-mask',
      width: 100,
    },
    {
      type: 'input-text',
      name: 'surname',
      draggable: null,
      header: 'Apellido',
      disabled: true,
      inputType: 'no-mask',
      width: 100,
    },
    {
      type: 'input-text',
      header: 'Colaboradores',
      name: 'numberOfWorkers',
      draggable: null,
      disabled: true,
      inputType: 'no-mask',
      width: 150,
    },
    {
      type: 'tags',
      name: 'period',
      draggable: null,
      header: 'Periodo',
      disabled: true,
      inputType: 'no-mask',
      width: 100,
    },
    {
      type: 'input-text',
      name: 'start_date',
      draggable: null,
      header: 'Fecha Inicial',
      disabled: true,
      inputType: 'no-mask',
      width: 125,
    },
    {
      type: 'input-text',
      name: 'end_date',
      draggable: null,
      header: 'Fecha Final',
      disabled: true,
      inputType: 'no-mask',
      width: 125,
    },
    {
      type: 'input-text',
      name: 'reintegration_date',
      draggable: null,
      header: 'F. Reintegro',
      disabled: true,
      inputType: 'no-mask',
      width: 125,
    },
    {
      type: 'input-text',
      name: 'communication_date',
      draggable: null,
      header: 'F. Comunicación',
      disabled: true,
      inputType: 'no-mask',
      width: 125,
    },
    {
      type: 'input-text',
      name: 'payment_date',
      draggable: null,
      header: 'F. de Pago',
      disabled: true,
      inputType: 'no-mask',
      width: 125,
    },
    {
      type: 'input-text',
      name: 'created_at',
      draggable: null,
      header: 'Creación',
      disabled: true,
      inputType: 'no-mask',
      width: 150,
    },
    {
      type: 'input-text',
      name: 'totalDays',
      draggable: null,
      header: 'D. Gozados',
      disabled: true,
      inputType: 'no-mask',
      width: 125,
    },
    {
      type: 'custom',
      component: ({ index }: { index: number }) => {
        const vacation = vacations?.data?.[index]

        return (
          <Menu
            items={[
              {
                id: 'export',
                label: 'Exportar Recibo',
                icon: <DocumentTextIcon />,
                disabled: !canExport,
                onClick: () => {
                  setTimeout(() => {
                    handleOpenExportReceiptModal({
                      type: 'vacations',
                      usersIds:
                        vacation?.users.map((user) => user.user_id) || [],
                      date: vacation?.payment_date || '',
                      value: vacation?.users[0]?.vacation_value || 0,

                      vacationId: vacation?.id || '',
                      description: vacation?.description || '',
                      title: vacation?.name || '',
                    })
                  }, 100)
                },
              },
              {
                id: 'edit',
                label: 'Editar',
                disabled: !canEdit,
                onClick: () => {
                  if (!vacation) {
                    return
                  }

                  creationMethods.reset({
                    create: defaultCreateVacationValues,
                  })

                  addTab('vacations', [
                    {
                      id: vacation.id,
                      type: 'edit',
                      label: vacation.name || 'Editar Vacaciones',
                    },
                  ])
                  setActiveTab('vacations', vacation.id)

                  // queryClient.prefetchQuery({
                  //   queryKey: ['vacation', vacation.id],
                  //   queryFn: () =>
                  //     fetchVacation({
                  //       id: vacation.id,
                  //     }),
                  // })
                },
                icon: <PencilSquareIcon />,
              },
              {
                id: '3',
                disabled: !canEdit,
                label: 'Excluir',
                icon: <TrashIcon />,
                onClick: () => {
                  setTimeout(() => setConfirmExclusionId(vacation?.id || ''), 1)
                },
              },
            ]}
          />
        )
      },
      name: 'dropdown',
      header: '',
      width: 50,
    },
  ]

  const {
    defaultColumnsOrder,
    defaultHiddenColumns,
    resetStateColumnOrder,
    saveColumnsOrderCallback,
    saveHiddenColumnsCallback,
    defaultLeftFixedColumns,
    defaultRightFixedColumns,
    saveLeftFixedColumnsCallback,
    saveRightFixedColumnsCallback,
  } = useSmartTableColumnManipulation({
    columns,
    tableOrderUniqueName: 'vacationsListColumnsOrder',
    tableHiddenUniqueName: 'vacationsListHiddenColumns',
    tableLeftFixedUniqueName: 'vacationsListLeftFixedColumns',
    tableRightFixedUniqueName: 'vacationsListRightFixedColumns',
  })

  const styles = useMemo(() => {
    return {
      borderInLines: true,
      centered: true,
    }
  }, [])

  return (
    <DialogContainer
      open={!!confirmExclusionId}
      onOpenChange={() => setConfirmExclusionId('')}
    >
      <FormProvider {...methods}>
        <Div css={{ height: 'calc(100vh - 60px)', overflow: 'hidden' }}>
          <ControlledFilterBar
            withAdvancedCells
            nameFilterName="filters.name"
            cellsIdsName="filters.cellsIds"
            isLoading={isFetchingVacations || isLoadingDeleteVacations}
            policiesIds={permissionsArray.filter((p) => p.includes('vacation'))}
          />
          <Div
            css={{
              position: 'relative',
              height: 'calc(100vh - 104px)',
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: 16,
              paddingRight: 16,
              overflowX: 'scroll',
              marginTop: '$4',
            }}
          >
            <SmartTable
              columns={columns}
              style={styles}
              withPagination
              noPadding
              areColumnsDraggable
              defaultColumnOrder={defaultColumnsOrder}
              defaultHiddenColumns={defaultHiddenColumns}
              maxDepth={1}
              resetColumnOrder={resetStateColumnOrder}
              saveColumnsOrderCallback={saveColumnsOrderCallback}
              saveHiddenColumnsCallback={saveHiddenColumnsCallback}
              defaultLeftFixedColumns={defaultLeftFixedColumns}
              defaultRightFixedColumns={defaultRightFixedColumns}
              saveLeftFixedColumnsCallback={saveLeftFixedColumnsCallback}
              saveRightFixedColumnsCallback={saveRightFixedColumnsCallback}
              paginationProps={{
                onChange: (data) => {
                  methods.setValue('pagination.page', data.pageIndex)
                  methods.setValue('pagination.perPage', data.pageSize)
                },
                page: pagination.page,
                perPage: pagination.perPage,
                totalPages: pagination.totalPages,
                total: pagination.total,
              }}
            />
          </Div>
        </Div>

        <SimpleDialog
          description={
            '¿Está seguro que desea excluir las vacaciones seleccionadas?'
          }
          title={'Excluir Vacaciones'}
          handleClose={() => setConfirmExclusionId('')}
          handleConfirm={() => {
            handleDeleteVacations([confirmExclusionId])
            setConfirmExclusionId('')
          }}
          icon={<TrashIcon />}
          overlayCss={{
            zIndex: 999,
          }}
          containerCSS={{
            zIndex: 9999,
          }}
          confirmLabel="Excluir"
        />
      </FormProvider>
    </DialogContainer>
  )
}
