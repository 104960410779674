import React, { createContext, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import {
  fetchWorkersHours,
  useAvailableCells,
  useWorkersHours,
} from '@/libs/react-query/hooks'
import dayjs from 'dayjs'
import { useDebounce, useExecuteOnceWhen } from '@/hooks'
import {
  useHandleCreateShiftSchedule,
  useHandleDeleteByScheduleIds,
  useHandleUpdateShiftSchedule,
} from '@/libs/react-query/mutations/shifts'
import {
  UpdateScheduledShiftsParams,
  UpdateShiftsParams,
} from '@/pages/turnos/components/SmartShiftManagement/context'
import {
  useHandleCreatePunch,
  useHandleCreatePunchBatch,
} from '@/libs/react-query/mutations/punchclock'
import {
  CreatePunchBatchPayload,
  CreatePunchPayload,
  DeletePunchPayload,
  UpdatePunchPayload,
  UpdateTotalHoursPayload,
} from '@/libs/react-query/mutations/punchclock/types'
import { useHandleUpdatePunch } from '@/libs/react-query/mutations/punchclock/useHandleUpdate'
import { useHandleDeletePunch } from '@/libs/react-query/mutations/punchclock/useHandleDelete'
import { useHandleUpdateTotalHours } from '@/libs/react-query/mutations/punchclock/useHandleUpdateTotalHours'
import { PunchesInfosTable, PunchesInfosTableType } from './types'
import { fromPunchesToPunchTable } from './parser'
import { zodResolver } from '@hookform/resolvers/zod'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { queryClient } from '@/libs/react-query'
import { useHandleUpdateAuthorizedHours } from '@/libs/react-query/mutations/punchclock/useHandleUpdateAuthorizedHours'
import { useTabStore } from '@/store'
import { useHandleCreateMultiplePunches } from '@/libs/react-query/mutations/punchclock/useHandleCreateMultiple'

interface WorkedHoursProviderProps {
  children: React.ReactNode
}

interface WorkedHoursContextData {
  isLoading: boolean
  handleUpdateShiftSchedule: ({
    params,
  }: {
    params: UpdateScheduledShiftsParams
  }) => Promise<void>
  handleCreateShiftSchedule: ({
    params,
    shiftId,
    isRest,
  }: {
    params: UpdateShiftsParams[]
    shiftId: string
    isRest?: boolean
  }) => Promise<void>
  handleCreatePunch: (p: CreatePunchPayload) => Promise<void>
  handleUpdatePunch: (p: UpdatePunchPayload) => Promise<void>
  handleCreatePunchBatch: (p: CreatePunchBatchPayload) => Promise<void>
  handleDeletePunch: (p: DeletePunchPayload) => Promise<void>
  handleUpdateTotalHours: (p: UpdateTotalHoursPayload) => Promise<void>
  handleDeleteByScheduleIds(p: { ids: string[] }): Promise<void>
}

export const WorkedHoursContext = createContext<WorkedHoursContextData>(
  {} as WorkedHoursContextData,
)

export const usePunchSummary = () => {
  const context = React.useContext(WorkedHoursContext)

  if (!context) {
    throw new Error('usePunchMatrix must be used within a PunchMatrizContext')
  }

  return context
}

export const PunchMatrixProvider = ({ children }: WorkedHoursProviderProps) => {
  const { autocompleteEnabled } = useTabStore((state) => ({
    autocompleteEnabled: state.punches.metadata?.isAutomaticCompleteEnabled,
  }))

  const { mutateAsync: handleCreatePunch, isLoading: isLoadingCreatePunch } =
    useHandleCreatePunch()

  const {
    mutateAsync: handleCreatePunchBatch,
    isLoading: isLoadingCreatePunchBatch,
  } = useHandleCreatePunchBatch()
  const {
    mutateAsync: handleDeletePunchBatch,
    isLoading: isLoadingDeletePunchBatch,
  } = useHandleDeletePunch()
  const { mutateAsync: handleUpdatePunch, isLoading: isLoadingUpdatePunch } =
    useHandleUpdatePunch()
  const {
    mutateAsync: handleCreateShiftSchedule,
    isLoading: isLoadingShiftSchedule,
  } = useHandleCreateShiftSchedule()
  const {
    mutateAsync: handleDeleteByScheduleIds,
    isLoading: isLoadingDeleteShiftSchedule,
  } = useHandleDeleteByScheduleIds()
  const {
    mutateAsync: handleUpdateShiftSchedule,
    isLoading: isLoadingUpdateSchedule,
  } = useHandleUpdateShiftSchedule()
  const {
    mutateAsync: handleUpdateTotalHours,
    isLoading: isLoadingUpdateTotalHours,
  } = useHandleUpdateTotalHours()
  const { isLoading: isLoadingAuthorizedHours } =
    useHandleUpdateAuthorizedHours()
  const { isLoading: isLoadingMultiplePunches } =
    useHandleCreateMultiplePunches()
  const { data: availableCells } = useAvailableCells(
    permissionsArray.filter((p) => p.includes('punches')),
  )

  const methods = useForm<PunchesInfosTableType>({
    resolver: zodResolver(PunchesInfosTable),
    defaultValues: {
      data: [],
      numberOfIntervals: 0,
      cellsIds: availableCells?.map((c) => c.id) || [],
      date: [new Date().toISOString()],
      name: '',
      map: {
        date: [new Date().toISOString()],
        name: '',
      },
      pagination: {
        page: 0,
        perPage: 30,
        total: 0,
        totalPages: 0,
      },
    },
  })

  const [date, cellsIds, name, page, perPage] = useWatch({
    control: methods.control,
    name: ['date', 'cellsIds', 'name', 'pagination.page', 'pagination.perPage'],
  })

  const debouncedCellsIds = useDebounce(cellsIds, 500)
  const debouncedName = useDebounce(name, 500)

  const { data: workedHours, isFetching: isLoadingWorkedHours } =
    useWorkersHours({
      date: dayjs(date[0]).format('YYYY-MM-DD'),
      cellsIds: debouncedCellsIds,
      name: debouncedName,
      page,
      perPage,
      autocomplete: autocompleteEnabled,
    })

  useExecuteOnceWhen(() => {
    methods.setValue('cellsIds', availableCells?.map((c) => c.id) || [])
  }, !!availableCells?.length)

  useEffect(() => {
    const { data, name, cellsIds, date: previousDate } = methods.getValues()

    if (
      !previousDate ||
      !workedHours?.data?.length ||
      !cellsIds.length ||
      isLoadingWorkedHours
    ) {
      return
    }

    const savedSelectedIds = data
      .filter((x) => x.checked.value)
      .map((x) => x.id)

    const newPunches = fromPunchesToPunchTable(
      workedHours.data,
      savedSelectedIds,
      previousDate,
      name,
      cellsIds,
    )

    methods.reset({
      data: newPunches.data,
      numberOfIntervals: newPunches.numberOfIntervals,
      cellsIds: newPunches.cellsIds,
      date: newPunches.date,
      name: newPunches.name,
      map: newPunches.map,
      pagination: {
        page: workedHours.page,
        perPage: workedHours.pageSize,
        total: workedHours.total,
        totalPages: workedHours.totalPages,
      },
    })
  }, [workedHours, isLoadingWorkedHours])

  return (
    <FormProvider {...methods}>
      <WorkedHoursContext.Provider
        value={{
          isLoading:
            isLoadingWorkedHours ||
            isLoadingUpdateSchedule ||
            isLoadingShiftSchedule ||
            isLoadingCreatePunch ||
            isLoadingUpdatePunch ||
            isLoadingCreatePunchBatch ||
            isLoadingDeletePunchBatch ||
            isLoadingUpdateTotalHours ||
            isLoadingDeleteShiftSchedule ||
            isLoadingAuthorizedHours ||
            isLoadingMultiplePunches,
          handleUpdateTotalHours,
          handleUpdateShiftSchedule,
          handleCreateShiftSchedule,
          handleCreatePunch,
          handleUpdatePunch,
          handleCreatePunchBatch,
          handleDeletePunch: handleDeletePunchBatch,
          handleDeleteByScheduleIds,
        }}
      >
        {children}
      </WorkedHoursContext.Provider>
    </FormProvider>
  )
}
