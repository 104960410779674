import { ChevronDownIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Input,
  DropdownContainer,
  InputContainer,
  TextInputContainer,
  DropdownItem,
} from './styles'
import { Text, TextInputProps } from '@punto-ui/react'
import * as Popover from '@radix-ui/react-popover'
import { Div } from '../Div'
import { defaultAppearingAnimationKeyframe } from '@/pages/styles/animations'
import { useOutsideAlerters } from '@/hooks/useOutsideAlerters'

export interface ISelectOption {
  label: string
  value: string
}

export interface SelectV2Props extends TextInputProps {
  status?: 'error' | 'success' | 'warning'
  onChangeValue?: (s: ISelectOption) => void
  options: ISelectOption[]
  value: string
  optionsWidth?: number
}

export const SearchableSelect = ({
  options,
  value: selectedOption,
  status,
  css,
  ...props
}: SelectV2Props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isFocused, setIsFocused] = React.useState(false)
  const [inputOptionValue, setInputOptionValue] = React.useState(
    options.find((o) => o.value === selectedOption)?.label || '',
  )
  const [definitiveRefElementWidth, setDefinitiveRefElementWidth] = useState(0)

  const timeoutRef = useRef<any>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const dropdownToggleRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const selected = options.find((o) => o.value === selectedOption)
    if (selected) {
      setInputOptionValue(selected.label)
    } else {
      setInputOptionValue('')
    }
  }, [selectedOption, options])

  useOutsideAlerters([inputRef, dropdownRef] as any, () => {
    setIsOpen(false)
  })

  useEffect(() => {
    const cleanTimeout = setTimeout(() => {
      setDefinitiveRefElementWidth((inputRef as any)?.current?.offsetWidth)
    }, 5)

    return () => {
      clearTimeout(cleanTimeout)
    }
  }, [])

  const currentOption = useMemo(() => {
    return options.find((o) => o.value === selectedOption)
  }, [options, selectedOption])

  const filteredOptions = useMemo(() => {
    return inputOptionValue !== currentOption?.label && inputOptionValue
      ? options?.filter((option) =>
          option.label.toLowerCase().includes(inputOptionValue.toLowerCase()),
        )
      : options
  }, [inputOptionValue, options, currentOption])

  return (
    <Popover.Root
      open={isFocused || isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen && isFocused) {
          return
        }

        setIsOpen(isOpen)

        if (isOpen) {
          timeoutRef.current = setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.focus()
            }
          }, 15)
        }
      }}
    >
      <DropdownContainer
        ref={containerRef}
        css={{
          zIndex: 9999,
          border: '1px solid',
          borderColor: isFocused
            ? '$brand_primary_pure'
            : status === 'warning'
            ? '$status_warning_pure'
            : status === 'error'
            ? '$status_danger_deep'
            : status === 'success'
            ? '$status_success_pure'
            : 'transparent',
          ...css,
        }}
      >
        <Popover.Trigger
          style={{
            all: 'unset',
            width: '100%',
          }}
        >
          <InputContainer>
            <TextInputContainer
              ref={dropdownToggleRef}
              disabled={props.disabled}
              onClick={() => {
                setIsFocused(true)
                inputRef?.current?.focus()
                inputRef?.current?.setSelectionRange(0, inputOptionValue.length)
              }}
              type="button"
              css={{
                borderColor: props.errorMessage
                  ? '$status_danger_deep'
                  : '$interface_dark_up',
                '& svg': {
                  color: props.errorMessage
                    ? '$status_danger_deep'
                    : '$interface_dark_up',
                },
              }}
            >
              <Input
                {...props}
                value={inputOptionValue}
                ref={inputRef}
                onChange={(e) => {
                  setInputOptionValue(e.target.value)
                  if (props.onChange) props.onChange(e)
                }}
                onFocus={(e) => {
                  setIsFocused(true)
                  if (props.onFocus) props.onFocus(e)
                }}
                onBlur={(e) => {
                  if (e.relatedTarget?.id !== 'searchable-select-item-button') {
                    setInputOptionValue(
                      options.find((o) => o.value === selectedOption)?.label ||
                        '',
                    )
                    setIsFocused(false)
                    if (props.onBlur) props.onBlur(e)
                  }
                }}
              />
              <ChevronDownIcon />
            </TextInputContainer>
            {props.errorMessage && (
              <Text size="xs" as="label" css={{ color: '$status_danger_deep' }}>
                {props.errorMessage}
              </Text>
            )}
          </InputContainer>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content sideOffset={5} asChild>
            <Div
              as="ul"
              ref={dropdownRef}
              css={{
                zIndex: 9999999,
                maxHeight: 350,
                width: props.optionsWidth || definitiveRefElementWidth + 40,
                overflowY: 'auto',

                pointerEvents: 'auto',
                overflowX: 'hidden',

                backgroundColor: '$interface_light_pure',
                borderRadius: '$md',

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',

                boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
                border: 'solid 1px $interface_light_down',
                padding: '$2',

                transition: 'opacity 0.5s ease-in-out',
                animation: `${defaultAppearingAnimationKeyframe} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

                '& button': {
                  all: 'unset',
                  padding: '$2',
                  cursor: 'pointer',
                  flex: 1,
                  borderRadius: '$md',

                  [`& ${Text}`]: {
                    color: '$interface_dark_down',
                  },

                  '&:hover': {
                    [`& ${Text}`]: {
                      color: '$brand_primary_pure',
                    },

                    backgroundColor: '$interface_light_up',
                  },
                },
              }}
              // isOpen={isFocused}
              // ref={dropdownRef}
              // css={{
              //   // position: 'absolute',
              //   // top: `${position.top + 12}px`,
              //   // left: `${position.left - 12}px`,
              // }}
            >
              {filteredOptions?.map((option) => (
                <DropdownItem
                  key={option.value}
                  isSelected={selectedOption === option.value}
                >
                  <button
                    type="button"
                    id="searchable-select-item-button"
                    onClick={() => {
                      props.onChangeValue?.(option)
                      setInputOptionValue(option.label)
                      setIsFocused(false)
                    }}
                  >
                    <Text
                      css={{
                        textOverflow: 'ellipsis',
                        maxWidth: 250,
                        overflow: 'hidden',
                      }}
                    >
                      {option.label}
                    </Text>
                  </button>
                </DropdownItem>
              ))}
            </Div>
          </Popover.Content>
        </Popover.Portal>
      </DropdownContainer>
    </Popover.Root>
  )
}

SearchableSelect.displayName = 'SearchableSelect'
