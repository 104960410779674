import { ControlledFilterBar, Div } from '@/components'
import { useFormContext, useWatch } from 'react-hook-form'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useHandleReceiptHubActions } from './actions'
import { useTabStore } from '@/store'
import React, { useMemo } from 'react'
import { useHandleUpdateReceipt } from '@/libs/react-query/mutations/reports/useHandleUpdateReceipt'
import { ExportReceipt } from '@/components/Drawers/ExportReceipt'
import useToast from '@/hooks/useToast'
import { useReportReceipts } from '@/libs/react-query/hooks/useReportReceipts'
import { useHandleCreateReceipt } from '@/libs/react-query/mutations/reports/useHandleCreateReceipt'
import { TableList } from '../components/TableList'
import { useHandleToggleFavoriteReceipt } from '@/libs/react-query/mutations/reports/useHandleToggleFavoriteReceipt'
import { useHandleDeleteReceipt } from '@/libs/react-query/mutations/reports/useHandleDeleteReceipt'
import {
  CurrencyDollarIcon,
  StarIcon,
  SunIcon,
  TableCellsIcon,
  UserMinusIcon,
} from '@heroicons/react/24/outline'
import { IReportsStateSchema } from '../reports-state'
import { Tag, TagProps } from '@punto-ui/react'

const tagColorByType: Record<string, TagProps['variant']> = {
  vacations: 'neutral',
  payment: 'neutral',
  liquidation: 'negative',
  payroll: 'positive',
}

const tagIconByType: Record<string, React.ElementType> = {
  vacations: SunIcon,
  payment: CurrencyDollarIcon,
  liquidation: UserMinusIcon,
  payroll: TableCellsIcon,
}

const tagLabelByType: Record<string, string> = {
  vacations: 'Vacaciones',
  payment: 'Pagos',
  liquidation: 'Liquidaciones',
  payroll: 'Salarios',
}

export const ReceiptHub = () => {
  const toast = useToast()

  const methods = useFormContext<IReportsStateSchema>()

  const isFavoriteFilter = useWatch({
    control: methods.control,
    name: 'filterFavorites',
  })

  const [selectedReceiptId, setSelectedReceiptId] = React.useState('')

  const { addTab, changeTab } = useTabStore((state) => ({
    addTab: state.addTab,
    changeTab: state.setActiveTab,
  }))

  const {
    mutateAsync: handleToggleFavorite,
    isLoading: isLoadingToggleFavorite,
  } = useHandleToggleFavoriteReceipt()
  const { isLoading: isLoadingUpdateReceipt } = useHandleUpdateReceipt()
  const {
    mutateAsync: handleCreateReceipt,
    isLoading: isLoadingCreateReceipt,
  } = useHandleCreateReceipt()
  const {
    mutateAsync: handleDeleteReceipt,
    isLoading: isLoadingDeleteReceipt,
  } = useHandleDeleteReceipt()
  const { data: receipts, isFetching: isLoadingReports } = useReportReceipts({
    name: '',
    page: 0,
    perPage: 100,
  })

  const handleDuplicate = async (id: string) => {
    const receipt = receipts?.data.find((r) => r.id === id)
    if (!receipt) return

    try {
      await handleCreateReceipt({
        name: `${receipt.name} - Copia`,
        title: receipt.title,
        description: receipt.description,
        type: receipt.type,
        favorite: receipt.favorite,
        headerType: receipt.headerType,
        withoutAmount: !!receipt.withoutAmount,
        headerTitle: receipt.headerTitle,
        footerType: receipt.footerType,
        footerCity: receipt.footerCity || '',
        concepts: receipt.concepts.map((concept) => ({
          label: concept.label,
          values: concept.values.map((value) => value.value),
        })),
      })

      toast.addToast({
        title: 'Recibo duplicado',
        type: 'positive',
        description: 'El recibo ha sido duplicado con éxito',
        id: Date.now(),
      })
    } catch (error) {
      console.log(error)

      toast.addToast({
        title: 'Error al duplicar recibo',
        type: 'negative',
        description: '',
        id: Date.now(),
      })
    }
  }

  useHandleReceiptHubActions()

  const formattedReceipts = useMemo(() => {
    return (
      receipts?.data
        .map((receipt) => ({
          id: receipt.id,
          name: receipt.name,
          isFavorite: receipt.favorite,
          isRemovable: !!receipt.identifier_id,
          isEditable: !!receipt.identifier_id,
          tagInName:
            receipt.identifier_id === null ? (
              <Tag variant="warning">Reporte Fijo</Tag>
            ) : null,
          tag:
            receipt.type in tagColorByType ? (
              <Tag variant={tagColorByType[receipt.type]}>
                {React.createElement(tagIconByType[receipt.type])}
                {tagLabelByType[receipt.type]}
              </Tag>
            ) : null,
          canDuplicate: true,
          isDownloadable: true,
        }))
        .filter((d) => {
          if (isFavoriteFilter) {
            return d.isFavorite
          }
          return true
        })
        .sort((a, b) => a.name.localeCompare(b.name)) || []
    )
  }, [receipts, isFavoriteFilter])

  const handleEdit = (id: string, name: string) => {
    addTab('reportsV2', [
      {
        id,
        type: 'receipt',
        label: `Editar ${name}`,
      },
    ])
    changeTab('reportsV2', id)
  }

  return (
    <>
      <Div
        css={{
          height: 'calc(100vh - 60px)',
        }}
      >
        <ControlledFilterBar
          policiesIds={permissionsArray.filter((p) => p.includes('reports'))}
          booleanFilters={[
            {
              label: 'Favoritos',
              value: 'favorite',
              name: 'filterFavorites',
              icon: <StarIcon />,
            },
          ]}
          isLoading={
            isLoadingUpdateReceipt ||
            isLoadingReports ||
            isLoadingCreateReceipt ||
            isLoadingDeleteReceipt ||
            isLoadingToggleFavorite
          }
          nameFilterName="configuration.name"
        />
        <Div
          css={{
            padding: '$4',
          }}
        >
          {/* <ReceiptList /> */}
          <TableList
            handleGenerate={(id: string) => setSelectedReceiptId(id)}
            handleDuplicate={handleDuplicate}
            handleDelete={handleDeleteReceipt}
            name="Recibos del Sistema"
            handleFavorite={(id: string) => handleToggleFavorite(id)}
            tables={formattedReceipts}
            handleEdit={handleEdit}
          />
        </Div>
      </Div>
      <ExportReceipt
        receiptId={selectedReceiptId}
        isOpen={!!selectedReceiptId}
        setIsOpen={() => setSelectedReceiptId('')}
      />
    </>
  )
}
