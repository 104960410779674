import { Avatar, ControlledFilterBar, Div } from '@/components'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import dayjs from 'dayjs'
import { useFormContext, useWatch } from 'react-hook-form'
import { PunchesInfosTableType } from './types'
import GoogleMapReact from 'google-map-react'
import { usePunchesList } from '@/libs/react-query/hooks/usePunchList'
import React, { useMemo, useRef, useState } from 'react'
import { useMyCompany } from '@/libs/react-query/hooks/useMyCompany'
import { ICompanyV2 } from '@/libs/react-query/types/company'
import { Text } from '@punto-ui/react'
import { IPunch, IWorkerUser } from '@/libs/react-query/types'
import { XMarkIcon } from '@heroicons/react/24/outline'

const DEFAULT_ZOOM = 8.5

interface MapDivProps extends React.ComponentProps<typeof Div> {
  lat?: number
  lng?: number
}

const MapDiv: React.FC<MapDivProps> = ({ lat, lng, ...props }) => (
  <Div {...props} />
)

export const PunchesMap = () => {
  const { control } = useFormContext<PunchesInfosTableType>()

  const [dates, name, cellsIds] = useWatch({
    control,
    name: ['map.date', 'map.name', 'cellsIds'],
  })
  const selectedDate = dayjs(dates?.[0]).toDate()
  const [zoom, setZoom] = React.useState(DEFAULT_ZOOM)
  const [center, setCenter] = React.useState({
    lat: -25.552070946032046,
    lng: -54.61649472454616,
  })

  const { data: punchList, isFetching: isFetchingPunches } = usePunchesList({
    startDate: dayjs(selectedDate).startOf('day').toISOString(),
    endDate: dayjs(selectedDate).endOf('day').toISOString(),
    name,
    cellsIds,
  })

  const groupedPunches = useMemo(() => {
    const punchesWithLocation = punchList?.filter(
      (punch) => punch.latitude && punch.longitude,
    )

    const groupedLocations = groupLocations(
      punchesWithLocation || [],
      pixelsToMeters(DEFAULT_ZOOM, zoom),
    )

    return groupedLocations
  }, [punchList, zoom])

  const { data: company } = useMyCompany()

  const map = useRef<any>()

  const [isZooming, setIsZooming] = React.useState(false)

  const [selectedDot, setSelectedDot] = useState<{
    lat: number
    lng: number
  }>({
    lat: 0,
    lng: 0,
  })

  return (
    <Div>
      <ControlledFilterBar
        dateName={'map.date'}
        nameFilterName="map.name"
        cellsIdsName="cellsIds"
        dateType="single"
        withAdvancedCells
        isLoading={isFetchingPunches}
        policiesIds={permissionsArray.filter((p) => p.includes('punches'))}
        tag={dayjs(selectedDate).format('dddd')}
      />
      <Div
        css={{
          height: 'calc(100vh - 100px)',
          width: '100%',
          position: 'relative',
        }}
      >
        {!!selectedDot.lat && !!selectedDot.lng && (
          <Div
            css={{
              position: 'absolute',
              top: 8,
              left: 8,
              zIndex: 2,

              display: 'flex',

              background: '$interface_light_pure',
              padding: '$2',
              borderRadius: '$md',
              border: '1px solid $interface_light_deep',
              boxShadow: '0px 0px 16px rgba(52, 58, 64, 0.08)',
            }}
          >
            <Div
              css={{
                minWidth: 132,
              }}
            >
              <Text variant="caption">{selectedDot.lat}</Text>
              <Text variant="caption">{selectedDot.lng}</Text>
            </Div>
            <Div
              css={{
                height: 16,
                width: 16,
                marginLeft: '$4',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSelectedDot({
                  lat: 0,
                  lng: 0,
                })
              }}
            >
              <XMarkIcon />
            </Div>
          </Div>
        )}
        <GoogleMapReact
          // yesIWantToUseGoogleMapApiInternals
          // bootstrapURLKeys={{ key: '' }}
          bootstrapURLKeys={{
            key: 'AIzaSyAcsDF9M9gUPgVFb1MtnuXPaMA18eX2hsQ',
          }}
          onChange={({ zoom, center }) => {
            setZoom(zoom)
            setCenter(center)
          }}
          onClick={(value) => {
            setSelectedDot({
              lat: value.lat,
              lng: value.lng,
            })
          }}
          onZoomAnimationStart={() => setIsZooming(true)}
          onZoomAnimationEnd={() => setIsZooming(false)}
          ref={map}
          center={center}
          defaultZoom={DEFAULT_ZOOM}
        >
          {/* {latitude && longitude && (
            <Marker lat={latitude} lng={longitude} url={pictureUrl} />
          )} */}
          {groupedPunches?.map((punch) => (
            <Marker
              key={punch.id}
              lat={punch.latitude}
              lng={punch.longitude}
              locations={punch.locations as any}
            />
          ))}
          {selectedDot.lat && selectedDot.lng && (
            <MapDiv
              css={{
                height: 10,
                width: 10,
                background: '$status_warning_deep',
                borderRadius: '$full',
              }}
              lat={selectedDot.lat}
              lng={selectedDot.lng}
            />
          )}
          {company?.companyPlaces.map((place) => {
            if (
              Number.isNaN(+place.latitude) ||
              Number.isNaN(+place.longitude)
            ) {
              return null
            }

            return (
              <Place
                showBorder={!isZooming}
                key={place.id}
                place={place}
                lat={+place.latitude}
                lng={+place.longitude}
                zoom={zoom}
              />
            )
          })}
        </GoogleMapReact>
      </Div>
    </Div>
  )
}

function metersToPixels(meters: number, zoom: number) {
  const earthCircumference = 40075000 // Earth's circumference in meters
  const scale = (256 * Math.pow(2, zoom)) / earthCircumference
  const pixels = meters * scale
  return pixels
}

function pixelsToMeters(pixels: number, zoom: number) {
  const earthCircumference = 40075000 // Earth's circumference in meters
  const scale = (256 * Math.pow(2, zoom)) / earthCircumference
  const meters = pixels / scale
  return meters
}

const Place = ({
  place,
  lat,
  lng,
  zoom,
  showBorder,
}: {
  place: ICompanyV2['companyPlaces'][0]
  lat: number
  lng: number
  zoom: number
  showBorder: boolean
}) => {
  return (
    <Div
      css={{
        width: metersToPixels(place.punches_radius, zoom) || 100,
        height: metersToPixels(place.punches_radius, zoom) || 100,
        // width: 100,
        // height: 100,
        position: 'relative',
      }}
    >
      <Div
        css={{
          position: 'absolute',
          top: '-50%',
          left: '-50%',

          width: metersToPixels(place.punches_radius, zoom) || 100,
          height: metersToPixels(place.punches_radius, zoom) || 100,
          borderRadius: '50%',
          backgroundColor: 'transparent',
          border: showBorder ? '2px solid $brand_primary_deep' : 'none',

          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          gap: '$2',
        }}
      >
        <Div
          css={{
            width: 20,
            height: 20,
            borderRadius: '50%',
            backgroundColor: '$brand_primary_deep',
          }}
        />
        <Text
          variant="caption"
          css={{
            color: '$brand_primary_deep',
          }}
        >
          {place.name}
        </Text>
      </Div>
    </Div>
  )
}

const Marker = ({
  lat,
  lng,
  locations,
}: {
  locations: Array<
    IPunch & {
      user: IWorkerUser
    }
  >
  lat: number
  lng: number
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Div
      css={{
        width: 20,
        height: 20,
        borderRadius: '50%',
        backgroundColor: '$brand_primary_pure',
        position: 'relative',
        // border: '2px solid $brand_primary_deep',
      }}
    >
      {isOpen && (
        <Div
          css={{
            zIndex: 3,
            position: 'absolute',
            top: 'calc(100% + 12px)',
            // left: '-50%',
            // width: 200,

            maxHeight: 200,
            overflowY: 'auto',

            background: '$interface_light_pure',
            borderRadius: '$md',
          }}
        >
          <Div
            css={{
              svg: {
                height: 16,
                width: 16,
                marginRight: '$2',
              },
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              height: 24,
              marginBottom: '$2',
              background: '$interface_light_up',
            }}
            onClick={() => {
              setIsOpen(false)
            }}
          >
            <XMarkIcon />
          </Div>
          {locations.map((location) => {
            return (
              <Div
                key={location.id}
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '$2',
                  marginBottom: '$2',

                  paddingLeft: '$4',
                  paddingRight: '$4',
                }}
              >
                <Avatar
                  src={location.user.photo_url || ''}
                  alt=""
                  height={16}
                  width={16}
                />
                <Text
                  css={{
                    whiteSpace: 'nowrap',
                  }}
                  variant="caption"
                >
                  {location.user.name}
                </Text>
                <Text variant="caption">
                  {dayjs(location.date).format('HH:mm')}
                </Text>
              </Div>
            )
          })}
        </Div>
      )}
      <Div
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        css={{
          position: 'absolute',
          bottom: '100%',
          left: '-50%',
          height: 40,
          width: 40,
          display: 'flex',
          zIndex: 2,
          alignItems: 'center',
          justifyContent: 'center',
          background: '$brand_primary_pure',
          borderRadius: '100%',

          border: '2px solid $brand_primary_deep',
        }}
      >
        {/* <Avatar src={url} alt="" height={30} width={30} /> */}
        <Text
          variant="caption"
          css={{
            color: 'white',
            fontWeight: 'bold',
          }}
        >
          {locations.length}
        </Text>
      </Div>
    </Div>
  )
}

interface ILocation {
  latitude: number
  longitude: number
  id: any
}

interface IGroupLocation {
  latitude: number // This could represent the centroid or a representative point.
  longitude: number
  locations: ILocation[]
  id: any
}

export function haversineDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  // Haversine formula to calculate the distance between two points on the Earth's surface
  const R = 6371e3 // Radius of the Earth in meters
  const phi1 = lat1 * (Math.PI / 180)
  const phi2 = lat2 * (Math.PI / 180)
  const deltaPhi = (lat2 - lat1) * (Math.PI / 180)
  const deltaLambda = (lon2 - lon1) * (Math.PI / 180)

  const a =
    Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
    Math.cos(phi1) *
      Math.cos(phi2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return R * c
}

function groupLocations(
  locations: ILocation[],
  maxDistance: number,
): IGroupLocation[] {
  const groups: IGroupLocation[] = []

  locations.forEach((location) => {
    let grouped = false
    for (const group of groups) {
      // Check if the location can join this group
      if (
        group.locations.some(
          (groupLoc) =>
            haversineDistance(
              location.latitude,
              location.longitude,
              groupLoc.latitude,
              groupLoc.longitude,
            ) <= maxDistance,
        )
      ) {
        group.locations.push(location)
        // Recalculate centroid
        group.latitude =
          group.locations.reduce((acc, loc) => acc + loc.latitude, 0) /
          group.locations.length
        group.longitude =
          group.locations.reduce((acc, loc) => acc + loc.longitude, 0) /
          group.locations.length
        grouped = true
        break
      }
    }

    // If location didn't fit into any existing group, create a new one
    if (!grouped) {
      groups.push({
        latitude: location.latitude,
        longitude: location.longitude,
        locations: [location],
        id: location.id,
      })
    }
  })

  return groups
}
