import { StyledContentText } from './styles'

import React, { useCallback, useEffect, useMemo } from 'react'

import {
  ControlledFilterBar,
  Div,
  Table as TableComponent,
  WorkersVisibility,
} from '@/components'

import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { usePayments } from '@/libs/react-query/hooks'

import {
  Box,
  Checkbox,
  DialogContainer,
  Menu,
  MenuProps,
  Tag,
  Text,
} from '@punto-ui/react'
import {
  IPayment,
  IPaymentTypes,
  paymentsDictionary,
} from '@/libs/react-query/types'

import { usePaymentContext } from '../../context'
import {
  debitPaymentsHeaderRaw,
  IValueTypes,
  paymentOptions,
  valueTypeDictionary,
} from '@/libs/react-query/types/payment'
import { typeDecoder } from '@/components/Forms/ControlledInput/toPattern'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import {
  BuildingLibraryIcon,
  DocumentTextIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import { useHandleDeletePayments } from '@/libs/react-query/mutations/payments/useHandleDelete'
import { permissionsArray } from '@/hooks/useGetAllPermissions'
import { useFormContext, useWatch } from 'react-hook-form'
import { AddPaymentSimpleData } from '../../form'
import { useCan, useDebounce } from '@/hooks'
import { useExportReceiptModal } from '@/components/ExportReceipt'
import { SimpleDialog } from '@/components/Dialogs/SimpleDialogs'
import { capitalizeFirstLetters } from '@/utils/workers/name'
import { useHandleRequestExportTemplate } from '@/libs/react-query/mutations/reports/useHandleRequestExportTemplate'
import useToast from '@/hooks/useToast'

export const PagosList = () => {
  const { filter: paymentsFilters, setFilter: setPaymentsFilters } =
    usePaymentContext()

  const { control } = useFormContext<AddPaymentSimpleData>()

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: paymentsFilters.page,
      pageSize: paymentsFilters.pageSize ? paymentsFilters.pageSize : 20,
    })

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const [cellsIds, nameFilter, dates, categories] = useWatch({
    control,
    name: [
      'filters.cellsIds',
      'filters.name',
      'filters.dates',
      'filters.categories',
    ],
  })

  const debouncedCellsIds = useDebounce(cellsIds, 250)
  const debouncedNameFilter = useDebounce(nameFilter, 250)
  const debouncedDates = useDebounce(dates, 250)
  const debouncedCategories = useDebounce(categories, 250)

  const { data: payments, isFetching: isFetchingPayments } = usePayments({
    ...paymentsFilters,
    startDate: debouncedDates?.[0]
      ? dayjs(debouncedDates[0]).startOf('day').toISOString()
      : undefined,
    endDate: debouncedDates?.[1]
      ? dayjs(debouncedDates[1]).endOf('day').toISOString()
      : debouncedDates?.[0]
      ? dayjs(debouncedDates[0]).endOf('day').toISOString()
      : undefined,
    types: debouncedCategories || [],
    cellsIds: debouncedCellsIds,
    name: debouncedNameFilter,
    page: pageIndex + 1,
    pageSize,
  })

  useEffect(() => {
    setPaymentsFilters((state) => ({
      ...state,
      page: pageIndex,
      pageSize,
    }))
  }, [pageIndex, pageSize, setPaymentsFilters])

  useEffect(() => {
    setPagination((state) => ({
      ...state,
      pageIndex: payments ? payments.page - 1 : 0,
    }))
    setPaymentsFilters((state) => ({
      ...state,
      page: payments ? payments.page - 1 : 0,
      pageSize: payments?.pageSize || 0,
    }))
  }, [setPaymentsFilters, payments])

  useEffect(() => {
    setPagination((state) => ({
      ...state,
      pageIndex: 0,
    }))
  }, [categories, dates, nameFilter, cellsIds])

  const columns = React.useMemo<ColumnDef<IPayment>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Box css={{ padding: '$3' }}>
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              onClick={table.getToggleAllRowsSelectedHandler()}
            />
          </Box>
        ),
        cell: ({ row }) => (
          <Box css={{ padding: '$3', background: 'transparent' }}>
            <Checkbox
              onClick={row.getToggleSelectedHandler()}
              disabled={!row.getCanSelect()}
              checked={row.getIsSelected()}
            />
          </Box>
        ),
        meta: {
          content: 'component',
        },
      },
      {
        header: 'Título',
        id: 'title',
        accessorFn: (row) => row.title,
        meta: {
          content: 'component',
        },
        cell: (info) => (
          <Div>
            <Text
              css={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: 200,
                overflow: 'hidden',
              }}
            >
              {capitalizeFirstLetters(info.getValue() as string)}
            </Text>
          </Div>
        ),
      },
      {
        header: 'Tipo',
        footer: (info) => info.column.id,
        cell: (info) => (
          <Div
            css={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 80,
            }}
          >
            <Tag
              variant={
                debitPaymentsHeaderRaw.includes(
                  info.row.original.payment_type as any,
                )
                  ? 'negative'
                  : 'positive'
              }
            >
              {info.getValue() as string}
            </Tag>
          </Div>
        ),
        accessorFn: (row) =>
          paymentsDictionary[row.payment_type as IPaymentTypes],
        id: 'payment_type',
        meta: {
          content: 'component',
        },
      },
      {
        header: '',
        footer: (info) => info.column.id,
        cell: (info) => (
          <Div>
            <Text
              css={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {capitalizeFirstLetters(info.row.original.users[0]?.name)}
            </Text>
          </Div>
        ),
        id: 'worker_name',
        meta: {
          content: 'component',
        },
      },
      {
        header: 'Colaboradores',
        footer: (info) => info.column.id,
        cell: (info) => <WorkersVisibility users={info.row.original.users} />,
        id: 'workers_visibility',
        meta: {
          content: 'component',
        },
      },
      {
        header: 'Tipo de Valor',
        footer: (info) => info.column.id,
        cell: (info) => info.getValue(),
        accessorFn: (row) => valueTypeDictionary[row.value_type as IValueTypes],
        id: 'value_type',
      },
      {
        header: 'Data de Pagamento',
        footer: (info) => info.column.id,
        cell: (info) => info.getValue(),
        accessorFn: (row) => dayjs(row.payment_date).format('DD/MM/YYYY'),
        id: 'payment_data',
      },
      {
        header: 'Valor',
        footer: (info) => info.column.id,
        cell: ({ row }) => {
          let value = ''

          if (row.original.value_type === 'VALUE') {
            value = typeDecoder('money-guarani').input(
              String(row.original.value),
            ) as string
          } else {
            value = typeDecoder('percentage').input(
              String(row.original.value),
            ) as string
          }

          return (
            <Div>
              <Text>{value}</Text>
            </Div>
          )
        },
        id: 'value',
        meta: {
          content: 'component',
        },
      },
      {
        header: 'Valor total',
        footer: (info) => info.column.id,
        cell: ({ row }) => {
          const totalValue = row.original.users.reduce(
            (acc, user) => acc + user.payment_final_value,
            0,
          )

          return (
            <Div>
              <Text>
                {
                  typeDecoder('money-guarani').input(
                    String(totalValue),
                  ) as string
                }
              </Text>
            </Div>
          )
        },
        id: 'value',
        meta: {
          content: 'component',
        },
      },
      {
        header: () => null,
        cell: (info) => (
          <StyledContentText>
            <DropdownMenu rowItem={info.row.original as IPayment} />
          </StyledContentText>
        ),
        footer: (info) => info.column.id,
        accessorFn: (row) => row.id,
        meta: {
          content: 'component',
        },
        id: 'dropdown',
      },
    ],
    [],
  )

  const table = useReactTable({
    data: payments?.data ?? [],
    columns,
    pageCount: payments?.totalPages ? payments.totalPages : -1,
    state: {
      pagination,
    },
    getRowId: (row) => row.id,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
  })

  const { isLoading: isLoadingDeletePayments } = useHandleDeletePayments()

  return (
    <Div>
      {/* <Filters /> */}
      <ControlledFilterBar
        // dateName={'date'}
        withAdvancedCells
        genericFilters={[
          {
            label: 'Categoria de Pago',
            name: 'filters.categories',
            single: false,
            options: paymentOptions,
            defaultValues: [],
          },
        ]}
        nameFilterName="filters.name"
        cellsIdsName="filters.cellsIds"
        dateType="range"
        dateName="filters.dates"
        isLoading={isFetchingPayments || isLoadingDeletePayments}
        policiesIds={[
          ...permissionsArray.filter((p) => p.includes('payments')),
          'deleted',
        ]}
        // tag={dayjs(selectedDate).format('dddd')}
      />
      <Div
        css={{
          margin: '$4',
        }}
      >
        <TableComponent<IPayment>
          numberOfRows={payments?.data?.length ?? 0}
          table={table}
          isLoading={isFetchingPayments}
          totalNumberOfRows={payments?.total ?? 0}
          css={{
            maxHeight: 'calc(100vh - 132px)',
          }}
          domTableCss={{
            maxHeight: 'calc(100vh - 200px)',
          }}
        />
      </Div>
    </Div>
  )
}

interface DropdownMenuProps {
  rowItem: IPayment
}
const DropdownMenu = ({ rowItem }: DropdownMenuProps) => {
  const { handleOpenExportReceiptModal } = useExportReceiptModal()
  const [isOpen, setIsOpen] = React.useState(false)

  const { mutateAsync: handleDeletePayments } = useHandleDeletePayments()
  const { handleOpenPopover } = usePaymentContext()

  const { mutateAsync: handleRequestExportTemplate } =
    useHandleRequestExportTemplate()

  const router = useRouter()
  const toast = useToast()
  const canEdit = useCan(['payments.edit'])
  const canDelete = useCan(['payments.delete'])

  const handleEditPayment = useCallback(() => {
    if (rowItem.batch_id) {
      router.push(`/pagos/create-batch?id=${rowItem.batch_id}`)
    } else {
      setTimeout(() => handleOpenPopover('add', rowItem), 100)
    }
  }, [handleOpenPopover, rowItem, router])

  const handleExportReceipt = () => {
    setTimeout(() => {
      handleOpenExportReceiptModal({
        type: 'payment',
        usersIds: rowItem.users.map((user) => user.user_id),
        date: rowItem.payment_date,
        value: rowItem.value,

        paymentId: rowItem.id,
        title: rowItem.title,
        description: rowItem.description,
      })
    }, 100)
  }

  const handleExportContinentalBankReport = async (templateId?: string) => {
    try {
      await handleRequestExportTemplate({
        template_id: templateId || 'fixed:bank-payment-report-continental',
        paymentId: rowItem.id,
        users_ids: rowItem.users.map((user) => user.user_id),
        filters: [],
      })

      toast.addToast({
        id: Date.now(),
        title: 'Reporte exportado correctamente',
        description: 'El reporte se encuentra en la sección de reportes',
        type: 'positive',
      })
    } catch (error) {
      console.log(error)

      toast.addToast({
        id: Date.now(),
        title: 'Error al exportar el reporte',
        description: 'Por favor, intente mas tarde',
        type: 'negative',
      })
    }
  }

  const memo = useMemo(() => {
    const items: MenuProps['items'] = [
      {
        id: '2',
        icon: <PencilSquareIcon />,
        label: 'Editar',
        onClick: handleEditPayment,
        disabled: !canEdit,
      },
      {
        id: '3',
        icon: <TrashIcon />,
        label: 'Excluir',
        onClick: () => {
          setTimeout(() => setIsOpen(true), 1)
        },
        disabled: !canDelete,
        divideBellow: true,
      },
      {
        id: '1',
        icon: <DocumentTextIcon />,
        label: 'Exportar Recibo',
        onClick: handleExportReceipt,
      },
      {
        id: '4',
        icon: <BuildingLibraryIcon />,
        label: 'Reportes Bancarios',
        children: [
          {
            id: '4.1',
            label: 'Banco Continental',
            onClick: () =>
              handleExportContinentalBankReport(
                'fixed:bank-payment-report-continental',
              ),
          },
          {
            id: '4.1',
            label: 'Banco Atlas',
            onClick: () =>
              handleExportContinentalBankReport(
                'fixed:bank-payment-report-atlas',
              ),
          },
        ],
      },
    ]

    return items
  }, [handleEditPayment, rowItem, canEdit, canDelete])

  if (!memo.length) return null

  return (
    <DialogContainer open={!!isOpen} onOpenChange={() => setIsOpen(false)}>
      <SimpleDialog
        description={'¿Está seguro que desea excluir el pago seleccionado?'}
        title={'Excluir Pago'}
        handleClose={() => setIsOpen(false)}
        handleConfirm={() => {
          handleDeletePayments([rowItem.id])
        }}
        icon={<TrashIcon />}
        overlayCss={{
          zIndex: 999,
        }}
        containerCSS={{
          zIndex: 9999,
        }}
        confirmLabel="Excluir"
      />
      <Menu items={memo} />
    </DialogContainer>
  )
}
