import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  ArrowPathIcon,
  UsersIcon,
  CalendarDaysIcon,
  ShieldCheckIcon,
  Cog6ToothIcon,
  TableCellsIcon,
  XMarkIcon,
  DocumentChartBarIcon,
  SunIcon,
  BugAntIcon,
  BuildingStorefrontIcon,
  EllipsisVerticalIcon,
  AcademicCapIcon,
  NewspaperIcon,
  MicrophoneIcon,
  ChatBubbleLeftRightIcon,
  CurrencyDollarIcon,
  ChartPieIcon,
  ComputerDesktopIcon,
  FolderIcon,
} from '@heroicons/react/24/outline'

import {
  draggable,
  dropTargetForElements,
  monitorForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter'
import { disableNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/disable-native-drag-preview'
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled'
import type { DragLocationHistory } from '@atlaskit/pragmatic-drag-and-drop/types'
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine'

import Company from '@/pages/empresa/index.page'
import Workers from '@/pages/colaboradores/index.page'
import Shifts from '@/pages/turnos/index.page'
import Punches from '@/pages/marcaciones/index.page'
import Movements from '@/pages/movimientos/index.page'
import Payments from '@/pages/pagos/index.page'
import Vacations from '@/pages/vacaciones/index.page'
import Planilla from '@/pages/planilla/index.page'
import Reports from '@/pages/reportes-v2/index.page'
import Permissions from '@/pages/permisos/index.page'
import Requests from '@/pages/solicitaciones/index.page'
import Configurations from '@/pages/configuraciones/index.page'
import Admin from '@/pages/admin/index.page'
import Documents from '@/pages/documents/index.page'

import { Div, SideBar, Touchable } from '@/components'
import {
  Button,
  ButtonProps,
  Divider,
  Progress,
  Text,
  Tooltip,
  styled,
} from '@punto-ui/react'
import { useUserPermissions } from '@/libs/react-query/hooks/useUserPermissions'
import * as Tabs from '@radix-ui/react-tabs'
import { useHorizontalScroll } from '@/hooks/useHorizontalScroll'
import { useMe } from '@/libs/react-query/hooks'
import { useLayoutStore } from '@/store/layout-store'
import { FaceIdIcon } from '@/assets/icons/FaceIdIcon'
import PagosContent from './auditoria/index.page'

export interface IRoute {
  permission?: string
  label: string
  path: string
  icon: React.ReactNode
  disabled?: boolean
  tag?: string
}

export const useRoutes = () => {
  const { data: permissions } = useUserPermissions()
  const { data: me } = useMe()

  const routes = useMemo(() => {
    const permissionedRoutes: IRoute[] = []
    const allRoutes = [
      {
        permission: 'company',
        label: 'Empresa',
        path: '/empresa',
        icon: <BuildingStorefrontIcon strokeWidth={2} />,
      },
      {
        permission: 'workers',
        label: 'Colaboradores',
        path: '/colaboradores',
        icon: <UsersIcon strokeWidth={2} />,
      },
      {
        permission: 'shift',
        label: 'Turnos',
        path: '/turnos',
        icon: <CalendarDaysIcon strokeWidth={2} />,
      },
      {
        permission: 'punches',
        label: 'Asistencia',
        path: '/marcaciones',
        icon: <FaceIdIcon strokeWidth={2} />,
      },
      {
        permission: 'moves',
        label: 'Movimientos',
        path: '/movimientos',
        icon: <ArrowPathIcon strokeWidth={2} />,
      },
      {
        permission: 'payments',
        label: 'Pagos',
        path: '/pagos',
        icon: <CurrencyDollarIcon strokeWidth={2} />,
      },
      {
        permission: 'vacation',
        label: 'Vacaciones',
        path: '/vacaciones',
        icon: <SunIcon strokeWidth={2} />,
      },
      {
        permission: 'payroll',
        label: 'Planilla Nominal',
        path: '/planilla',
        icon: <TableCellsIcon strokeWidth={2} />,
      },
      {
        permission: 'reports',
        label: 'Reportes',
        path: '/reportes-v1',
        icon: <DocumentChartBarIcon strokeWidth={2} />,
      },
      {
        permission: 'reports',
        label: 'Generación de Reportes',
        path: '/reportes-v2',
        disabled: false,
        icon: <ChartPieIcon strokeWidth={2} />,
      },
      // {
      //   label: 'Reportes',
      //   path: '/reportes',
      //   icon: <ChartPieIcon strokeWidth={2} />,
      // },
      // {
      //   label: 'Cuestionarios',
      //   path: '/cuestionarios',
      //   icon: <ClipboardDocumentListIcon strokeWidth={2} />,
      // },
      {
        permission: 'permisos',
        label: 'Permisos y Seguridad',
        path: '/permisos',
        icon: <ShieldCheckIcon strokeWidth={2} />,
      },
      {
        permission: 'audit',
        label: 'Auditoría',
        path: '/auditoria',
        icon: <ComputerDesktopIcon strokeWidth={2} />,
      },
      {
        permission: 'requests',
        label: 'Solicitaciones',
        path: '/solicitaciones',
        icon: <ChatBubbleLeftRightIcon strokeWidth={2} />,
      },
      {
        permission: 'documents',
        label: 'Documentos',
        path: '/documents',
        icon: <FolderIcon strokeWidth={2} />,
        tag: 'En teste',
      },
      {
        permission: 'configurations',
        label: 'Configuraciones',
        path: '/configuraciones',
        icon: <Cog6ToothIcon strokeWidth={2} />,
      },
      // {
      //   label: 'Salidas',
      //   path: '/vacaciones',
      //   icon: <ArrowLeftOnRectangleIcon strokeWidth={2}  />,
      // },
      // {
      //   label: 'Tutoriales',
      //   path: '/tutoriales',
      //   icon: <ClipboardIcon strokeWidth={2} />,
      // },
    ]

    allRoutes.forEach((route) => {
      const userHasPermissionForRoute = permissions?.some((permission) =>
        permission.includes(route.permission),
      )
      if (userHasPermissionForRoute) {
        permissionedRoutes.push(route)
      }
    })

    if (me?.isAdmin) {
      permissionedRoutes.push({
        label: 'Admin',
        path: '/admin',
        icon: <BugAntIcon strokeWidth={2} />,
      })
    }

    return permissionedRoutes
  }, [permissions, me])

  const publicRoutes = [
    {
      label: 'Academia Punto OK',
      path: '/academy',
      icon: <AcademicCapIcon strokeWidth={2} />,
      disabled: true,
    },
    {
      label: 'Blog',
      path: '/blog',
      disabled: true,
      icon: <NewspaperIcon strokeWidth={2} />,
    },
    {
      label: 'PuntoCast',
      disabled: true,
      path: '/punto-cast',
      icon: <MicrophoneIcon strokeWidth={2} />,
    },
  ]

  return {
    routes,
    publicRoutes,
  }
}

export interface IRouteTab {
  icon: React.ReactNode
  label: string
  value: string
  scrollable?: boolean
  component: React.ReactNode
  disabled?: boolean
  disableMessage?: string
  closeable?: boolean
  handleClose?: () => void
}

export interface IRouteAction {
  icon: React.ReactNode
  label: string
  variant: ButtonProps['variant']
  size?: ButtonProps['size']
  action: () => void
  disabled?: boolean
  disableMessage?: string
  isLoading?: boolean
  width?: number
}

export interface IRouteCustomAction {
  component: React.ReactNode
}

interface SideBarRouterProps {
  tabs?: IRouteTab[]
  children?: React.ReactNode
  action?: React.ReactNode
  actions?: IRouteAction[]
  customActions?: IRouteCustomAction[]
  defaultTab?: string
  tab?: string
  onChangeTab?: (tab: string) => void
  isLoading?: boolean
  setTab?: (tab: string) => void
  onlyTabs?: boolean
}

const widths = {
  start: typeof window !== 'undefined' ? window.innerWidth * 0.5 : 200,
  min: typeof window !== 'undefined' ? window.innerWidth * 0.05 : 200,
  max: typeof window !== 'undefined' ? window.innerWidth * 0.9 : 200,
}

// console.log(widths)

function getProposedWidth({
  initialWidth,
  location,
}: {
  initialWidth: number
  location: DragLocationHistory
}): number {
  const diffX = location.current.input.clientX - location.initial.input.clientX
  const proposedWidth = initialWidth + diffX

  // ensure we don't go below the min or above the max allowed widths
  return Math.min(Math.max(widths.min, proposedWidth), widths.max)
}

type State =
  | {
      type: 'idle'
    }
  | {
      type: 'dragging'
    }

export const Router = ({
  tabs,
  action,
  actions,
  customActions,
  defaultTab,
  children,
  tab,
  isLoading,
  onChangeTab,
  onlyTabs,
  setTab,
}: SideBarRouterProps) => {
  const scrollRef = useHorizontalScroll<HTMLDivElement>()

  const tabDropArea2Ref = useRef<HTMLDivElement | null>(null)
  const tabDropAreaRef = useRef<HTMLDivElement | null>(null)
  const dropAreaRef = useRef<HTMLDivElement | null>(null)
  const dividerRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [state, setState] = useState<State>({
    type: 'idle',
  })

  const {
    setLayoutWidth,
    layoutWidth,
    setDualLayoutScreen,
    closeLayoutTab,
    dualLayoutScreen,
  } = useLayoutStore((store) => ({
    setDualLayoutScreen: (screen: string) => {
      store.setLayoutTab(screen as any, 0)
    },
    layoutWidth: store.layoutWidth,
    setLayoutWidth: store.setLayoutWidth,
    dualLayoutScreen: store.layoutScreens[0],
    closeLayoutTab: () => store.closeLayoutTab(0),
  }))

  const [initialWidth, setInitialWidth] = useState(layoutWidth || widths.start)

  const SelectedDualLayout =
    dualLayoutScreen && layouts[dualLayoutScreen]
      ? layouts[dualLayoutScreen]
      : null

  const [isDraggingNavigation, setIsDraggingNavigation] = useState(false)
  const [isDraggingInternalTab, setIsDraggingInternalTab] = useState(false)

  useEffect(() => {
    return monitorForElements({
      canMonitor: ({ source }) => source.data.type === 'layout-screen',
      onDrag: ({ source }) => {
        if (isDraggingNavigation) return

        setIsDraggingNavigation(true)
      },
      onDrop: () => {
        setIsDraggingNavigation(false)
      },
    })
  }, [isDraggingNavigation])

  useEffect(() => {
    return monitorForElements({
      canMonitor: ({ source }) =>
        source.data.type ===
        (onlyTabs ? 'tab-layout-screen' : 'tab-layout-screen-2'),
      onDrag: ({ source }) => {
        console.log('dragging internal tab', source.data)
        if (isDraggingInternalTab) return

        setIsDraggingInternalTab(true)
      },
      onDrop: () => {
        setIsDraggingInternalTab(false)
      },
    })
  }, [isDraggingInternalTab])

  useEffect(() => {
    if (!dropAreaRef.current) return

    return dropTargetForElements({
      element: dropAreaRef.current,
      canDrop: ({ source }) => source.data.type === 'layout-screen',
      onDrop: ({ source }) => {
        setDualLayoutScreen(source.data.screen as string)
        setIsDraggingNavigation(false)
      },
    })
  }, [isDraggingNavigation])

  useEffect(() => {
    if (!tabDropAreaRef.current) return

    return dropTargetForElements({
      element: tabDropAreaRef.current,
      canDrop: ({ source }) => source.data.type === 'tab-layout-screen',
      onDrop: ({ source }) => {
        // setDualLayoutScreen(source.data.screen as string)
        setIsDraggingInternalTab(false)
      },
    })
  }, [isDraggingNavigation])

  useEffect(() => {
    if (!tabDropArea2Ref.current) return

    return dropTargetForElements({
      element: tabDropArea2Ref.current,
      canDrop: ({ source }) => source.data.type === 'tab-layout-screen-2',
      onDrop: ({ source }) => {
        // setDualLayoutScreen(source.data.screen as string)
        setIsDraggingInternalTab(false)
      },
    })
  }, [isDraggingNavigation])

  useEffect(() => {
    const divider = dividerRef.current

    if (!divider) return

    return combine(
      draggable({
        element: divider,
        onGenerateDragPreview: ({ nativeSetDragImage }) => {
          disableNativeDragPreview({ nativeSetDragImage })
          preventUnhandled.start()
        },
        onDragStart() {
          setState({ type: 'dragging' })
        },
        onDrag({ location }) {
          contentRef.current?.style.setProperty(
            '--local-resizing-width',
            `${getProposedWidth({ initialWidth, location })}px`,
          )

          // setLayoutWidth(getProposedWidth({ initialWidth, location }))
        },
        onDrop({ location }) {
          preventUnhandled.stop()
          setState({ type: 'idle' })

          setLayoutWidth(getProposedWidth({ initialWidth, location }))
          setInitialWidth(getProposedWidth({ initialWidth, location }))
          contentRef.current?.style.removeProperty('--local-resizing-width')
        },
      }),
    )
  }, [initialWidth, isDraggingNavigation])

  // console.log('re-render')

  return (
    <SideBarContainer>
      {!onlyTabs && <SideBar />}
      {tabs && (
        <TabsRoot
          style={
            !onlyTabs
              ? ({
                  // flexGrow: '1',
                  // flexShrink: '1',
                  '--local-initial-width': `${initialWidth}px`,
                  width: SelectedDualLayout
                    ? `var(--local-resizing-width, ${initialWidth}px)`
                    : '100%',
                  // width: '100%',
                  position: 'relative',
                  pointerEvents: state.type === 'dragging' ? 'none' : undefined,
                } as CSSProperties)
              : {
                  // overflow: 'scroll',
                }
          }
          ref={contentRef}
          defaultValue={defaultTab}
          value={tab}
          onValueChange={onChangeTab}
        >
          <TabsList>
            <Div
              ref={scrollRef}
              css={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: '95vw',
                maxHeight: 60,
                minHeight: 60,
                overflow: 'auto',
                height: '100%',
                width: '100%',

                '&::-webkit-scrollbar': {
                  width: 0,
                  height: 0,
                },
              }}
            >
              {tabs.map((tab) => (
                <TabTrigger
                  key={tab.value}
                  tab={tab}
                  setTab={setTab}
                  onlyTabs={onlyTabs}
                />
              ))}
              {isLoading && (
                <Div css={{ marginLeft: '$2', marginRight: 'auto' }}>
                  <Progress />
                </Div>
              )}
            </Div>

            <Div
              css={{
                marginLeft: 'auto',
                marginRight: '$4',
              }}
            >
              {!!action && (
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {action}
                </Div>
              )}
              {!!actions && (
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {actions.map((action) => (
                    <>
                      {action.disableMessage ? (
                        <Tooltip
                          message={action.disableMessage}
                          key={action.label}
                          arrow
                          side={'left'}
                        >
                          <Div>
                            <Button
                              size={action.size || 'md'}
                              isLoading={action.isLoading}
                              variant={action.variant || 'tertiary'}
                              icon={action.icon}
                              disabled={action.disabled}
                              onClick={action.action}
                              css={{
                                width: action.width,
                                marginLeft: '$2',
                              }}
                            >
                              {action.label}
                            </Button>
                          </Div>
                        </Tooltip>
                      ) : (
                        <Button
                          variant={action.variant || 'tertiary'}
                          icon={action.icon}
                          size={action.size || 'md'}
                          disabled={action.disabled}
                          isLoading={action.isLoading}
                          onClick={action.action}
                          css={{
                            marginLeft: '$2',
                            width: action.width,
                          }}
                        >
                          {action.label}
                        </Button>
                      )}
                    </>
                  ))}
                </Div>
              )}
              {!!customActions && (
                <Div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {customActions.map((action) => action.component)}
                </Div>
              )}
            </Div>
          </TabsList>
          <Div
            css={{
              overflow: 'scroll',
              maxHeight: '100vh',
              maxWidth: '100vw',
              width: '100%',
              height: '100%',
              // minHeight: '100vh',

              minHeight: 'calc(100vh - 60px)',

              flex: 1,
              display: 'flex',
              // background: '$interface_light_up',
            }}
          >
            {tabs.map((tab) => (
              <TabsContent
                scrollable={tab.scrollable}
                value={tab.value}
                key={tab.value}
              >
                {tab.component}
              </TabsContent>
            ))}
          </Div>
          {isDraggingInternalTab && (
            <Div
              ref={tabDropAreaRef}
              css={{
                position: 'absolute',
                top: 0,
                left: '50%',
                right: 0,
                bottom: 0,
                zIndex: 999999,
                opacity: 0.5,
                backgroundColor: '$interface_dark_down',

                display: 'flex',
              }}
            />
          )}
        </TabsRoot>
      )}
      {!onlyTabs && dualLayoutScreen && SelectedDualLayout && (
        <>
          <LayoutDraggable
            layoutRef={dividerRef}
            isDragging={state.type === 'dragging'}
          />
          <Div
            css={{
              // maxWidth: '40vw',
              // width: '40vw',
              flex: 1,
              // overflowY: 'hidden',
              height: '100vh',
              position: 'relative',
            }}
          >
            <Div
              css={{
                position: 'absolute',
                bottom: 8,
                right: 8,
                zIndex: 999999,

                cursor: 'pointer',
              }}
            >
              <Button
                variant={'primaryCritical'}
                size="md"
                icon={<XMarkIcon />}
                onClick={() => {
                  closeLayoutTab()
                }}
              />
            </Div>
            <Div
              css={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,

                background: '$interface_light_up',
              }}
            >
              <SelectedDualLayout onlyTabs />
              {isDraggingInternalTab && onlyTabs && (
                <Div
                  ref={tabDropArea2Ref}
                  css={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    right: 0,
                    bottom: 0,
                    zIndex: 999999,
                    opacity: 0.5,
                    backgroundColor: '$interface_dark_down',

                    display: 'flex',
                  }}
                />
              )}
            </Div>
            {isDraggingNavigation && (
              <Div
                ref={dropAreaRef}
                css={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 999999,
                  opacity: 0.5,
                  backgroundColor: '$interface_dark_down',

                  display: 'flex',
                }}
              />
            )}
          </Div>
        </>
      )}
      {!tabs && !onlyTabs && (
        <Div
          css={{
            maxHeight: '100vh',
            width: '100%',
          }}
        >
          {children}
        </Div>
      )}
      {!onlyTabs && !SelectedDualLayout && isDraggingNavigation && (
        <Div
          ref={dropAreaRef}
          css={{
            position: 'absolute',
            top: 0,
            left: '50%',
            right: 0,
            bottom: 0,
            zIndex: 100,
            opacity: 0.5,
            backgroundColor: '$interface_dark_down',

            display: 'flex',
          }}
        />
      )}
    </SideBarContainer>
  )
}

const LayoutDraggable = ({
  layoutRef,
  isDragging,
}: {
  layoutRef: any
  isDragging: boolean
}) => {
  return (
    <Div
      css={{
        height: '100vh',
        width: 8,
        cursor: 'grab',
        background: isDragging ? '$interface_dark_up' : '$interface_light_deep',
        pointerEvents: isDragging ? 'none' : undefined,

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        svg: {
          height: 24,
          width: 24,
          color: '$interface_dark_down',
        },

        position: 'relative',
      }}
    >
      <Div
        ref={layoutRef}
        css={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: -16,
          background: 'transparent',
          width: 40,
          overflow: 'visible',
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <EllipsisVerticalIcon />
      </Div>
    </Div>
  )
}

const TabTrigger = ({
  tab,
  setTab,
  onlyTabs,
}: {
  tab: IRouteTab
  setTab?: (tab: string) => void
  onlyTabs?: boolean
}) => {
  const tabRef = useRef<any>(null)

  useEffect(() => {
    if (!tabRef.current) return

    return draggable({
      element: tabRef.current,

      getInitialData: () => ({
        type: onlyTabs ? 'tab-layout-screen' : 'tab-layout-screen-2',
        screen: tab.value,
      }),
    })
  }, [])

  return (
    <Tooltip message={tab.disableMessage} arrow>
      <Div
        // ref={tabRef}
        css={{
          display: 'flex',
          height: '100%',

          opacity: tab.disabled ? 0.5 : 1,
          cursor: tab.disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <TabsTrigger
          css={
            {
              // cursor: 'grab',
            }
          }
          disabled={tab.disabled}
          // ref={tabRef}
          value={tab.value}
          key={tab.value}
          onClick={() => setTab?.(tab.value)}
        >
          {tab.icon}
          <Text
            css={{
              marginLeft: '$2',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {tab.label}
          </Text>
          {tab.closeable && (
            <Touchable
              onClick={tab.handleClose}
              css={{
                marginTop: 3,
                marginLeft: '$2',
                svg: {
                  color: '$interface_dark_deep',
                  height: 16,
                  width: 16,
                },
              }}
            >
              <XMarkIcon />
            </Touchable>
          )}
        </TabsTrigger>
        <Divider
          orientation="vertical"
          css={{
            margin: 'auto 0',
            height: 24,
          }}
        />
      </Div>
    </Tooltip>
  )
}

const layouts: Record<string, any> = {
  company: () => <Company onlyTabs />,
  workers: () => <Workers onlyTabs />,
  shift: () => <Shifts onlyTabs />,
  punches: () => <Punches onlyTabs />,
  moves: () => <Movements onlyTabs />,
  payments: () => <Payments onlyTabs />,
  vacation: () => <Vacations onlyTabs />,
  payroll: () => <Planilla onlyTabs />,
  reports: () => <Reports onlyTabs />,
  requests: () => <Requests onlyTabs />,
  permisos: () => <Permissions onlyTabs />,
  audit: () => <PagosContent onlyTabs />,
  configurations: () => <Configurations onlyTabs />,
  admin: () => <Admin onlyTabs />,
  documents: () => <Documents onlyTabs />,
}

const SideBarContainer = styled('div', {
  display: 'flex',
  maxHeight: '100vh',
  maxWidth: '100vw',
})

const TabsRoot = styled(Tabs.Root, {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxHeight: 'calc(100vh)',
  overflow: 'hidden',
})

const TabsList = styled(Tabs.List, {
  display: 'flex',
  alignItems: 'center',

  maxHeight: 44,
  overflow: 'hidden',
  maxWidth: '100vw',
  height: '100%',
  width: '100%',
  borderBottom: '1px solid',
  borderColor: '$interface_light_down',

  background: '$interface_light_pure',
})

const TabsTrigger = styled(Tabs.Trigger, {
  all: 'unset',
  userSelect: 'none',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  color: '$interface_dark_deep',

  '&:hover': {
    color: '$brand_primary_pure',
  },

  height: '100%',

  paddingRight: '$6',
  paddingLeft: '$6',

  borderBottom: '1px solid',
  borderColor: '$interface_light_pure',

  '&[data-state="active"]': {
    [`${Text}`]: {
      color: '$brand_primary_pure',
    },

    svg: {
      color: '$brand_primary_pure',
    },

    borderColor: '$brand_primary_pure',
  },

  svg: {
    height: 20,
    width: 20,
    color: '$interface_dark_deep',
  },
})

const TabsContent = styled(Tabs.Content, {
  flex: 1,
  overflow: 'hidden',
  backgroundColor: '$interface_light_up',
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
  outline: 'none',

  position: 'relative',

  variants: {
    scrollable: {
      true: {
        overflow: 'scroll',
      },
      false: {
        overflow: 'hidden',
      },
    },
  },
})
