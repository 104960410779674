import { IDefaultApiPagination } from '../pagination'
import { IDefaultModuleContextProps } from '../context'
import { PlanType } from '@/hooks/useIsFeatureInPlan'

export type ILiquidationBasicConcepts =
  | 'discount_proportional_salary'
  | 'proportional_salary'
  | 'proportional_aguinaldo'
  | 'caused_vacations'
  | 'proportional_vacations'
  | 'accumulated_vacations'
  | 'prewarning'
  | 'familiar_bonus'
  | 'discount_prewarning'
  | 'indemnification'
  | 'vacations_antecipate'
  | 'ips9'

export const LIQUIDATION_CONCEPTS: ILiquidationBasicConcepts[] = [
  'proportional_salary',
  'proportional_aguinaldo',
  'caused_vacations',
  'proportional_vacations',
  'accumulated_vacations',
  'familiar_bonus',
  'prewarning',
  'indemnification',
  'discount_prewarning',
  'discount_proportional_salary',
  'vacations_antecipate',
  'ips9',
]

export const LIQUIDATION_LABEL_BY_CONCEPT: Record<
  ILiquidationBasicConcepts,
  string
> = {
  ips9: 'IPS 9%',
  proportional_salary: 'Haberes Salário Proporcional',
  discount_proportional_salary: 'Descuento Salário Proporcional',
  proportional_aguinaldo: 'Aguinaldo Proporcional',
  caused_vacations: 'Vacaciones Causadas',
  accumulated_vacations: 'Vacaciones Acumuladas',
  familiar_bonus: 'Bonificación Familiar',
  proportional_vacations: 'Vacaciones Proporcionales',
  prewarning: 'Preaviso',
  indemnification: 'Indemnización',
  vacations_antecipate: 'Anticipo de vacaciones',
  discount_prewarning: 'Descuento Preaviso',
}

export type IUpdateUserLiquidationDTO = {
  liquidation_id?: string
  user_id: string

  status?: string
  antiquity_year?: number | null
  antiquity_month?: number | null
  entrance_date?: string | null
  entrance_date_ips?: string | null
  inactivation_date?: string | null
  complement?: string
  reason?: string
  signature_date?: string
  inactivation_type?: string
  contract_type?: string

  salary?: {
    base_salary?: number | null
    payed_days?: number | null
    proportional_salary?: number | null
    extra_morning?: number | null
    extra_morning_hours?: number | null
    holiday_morning?: number | null
    holiday_morning_hours?: number | null
    discount_morning?: number | null
    discount_morning_hours?: number | null
    ordinary_night?: number | null
    ordinary_night_hours?: number | null
    extra_night?: number | null
    extra_night_hours?: number | null
    holiday_night_hours?: number | null
    discount_night_hours?: number | null
    discount_night?: number | null
    holiday_night?: number | null
    final_salary?: number | null
    ips_base?: number | null
    ips9?: number | null
    ips16?: number | null
    accountPayments?: number | null
    total_to_receive?: number | null

    total_positive_salary?: number | null
    total_negative_salary?: number | null

    payments?: {
      label: string
      value?: number | null
    }[]
    discounts?: {
      label: string
      value?: number | null
    }[]
  }

  salaries?: {
    month: number
    value: number | null
    payrollId: string | null
  }[]
  salary_average?: number | null
  daily_salary?: number | null
  last_salaries_daily?: number | null

  aguinaldo_months?: {
    month: number
    value: number | null
    payrollId: string | null
  }[]
  aguinaldo?: number | null

  vacations?: {
    earned_days?: number | null
    used_days?: number | null
    value?: number | null
    year?: {
      label: string
      value?: number | null
    }
  }[]
  total_vacations?: {
    total_caused_vacations?: number | null
    used_days?: number | null
    earned_days?: number | null
    value?: number | null
  }
  total_accumulated_vacations?: {
    total_caused_vacations?: number | null
    used_days?: number | null
    earned_days?: number | null
    value?: number | null
  }
  proportional_vacations?: {
    corresponding_days?: number | null
    received_days?: number | null
    missing_days?: number | null
    value?: number | null
  }
  discount_proportional_vacations?: {
    corresponding_days?: number | null
    received_days?: number | null
    missing_days?: number | null
    value?: number | null
  }

  pre_warning?: {
    age?: number | null
    totalDays?: number | null
    received_days?: number | null
    missing_days?: number | null
    value?: number | null
  }

  discount_pre_warning?: {
    age?: number | null
    totalDays?: number | null
    received_days?: number | null
    missing_days?: number | null
    value?: number | null
  }

  indemnification?: {
    corresponding_days?: number | null
    value?: number | null
  }

  concepts?: Array<{
    amount?: number | null
    value?: number | null
    type: string
    id: string
  }>

  family_bonus?: {
    value?: number | null
    children?: number | null
  }
}

export interface IUpdateUsersCompanyConfigsDTO {
  users_ids: string[]

  payment_config_id?: string
  punch_config_id?: string
  vacation_config_id?: string
  aguinaldo_config_id?: string
  liquidation_config_id?: string
}

export type ILiquidationConceptTypes = 'basic' | 'payment'

export type IUpdateUserLiquidationConceptDTO = {
  name?: string | null
  ips?: boolean | null
  is_active?: boolean | null

  type: string // 'basic' | 'payment'
  entity_id: string
  user_id: string
  liquidation_id?: string
}

export type IUpdateUserLiquidationConceptsDTO = {
  name?: string | null
  ips?: boolean | null
  is_active?: boolean | null

  entities: {
    type: string // 'basic' | 'payment'
    id: string
  }[]
  user_id: string
  liquidation_id?: string
}

export interface IUserAllowedPunch {
  faceIdLocation: boolean
  qrCode: boolean
  faceId: boolean
  location: boolean
  phone: boolean
  everywhere: boolean
  showQrCode: boolean
}

export interface ICompanyAllowedPunch {
  showQrCode: boolean
  readQrCode: boolean
  faceId: boolean
  faceIdActive: boolean
}

export interface IHoursMetrics {
  intervals: {
    subtractByDefault: {
      morning: boolean
      night: boolean
    }
  }
}
export interface ICompany {
  _id: string
  plan: string
  manager: string[]
  type: 'matriz' | 'sucursal'
  defaultSchedule: string
  ruc: string
  razaoSocial: string
  nomeFantasia: string
  photo?: string
  phone?: string
  sectors?: [string]
  legalRepresentation?: string
  nicknameRepresentLegal?: string
  nameRepresentLegal?: string
  numberPatronal?: string
  numberIps?: string
  numberEstablishment?: string
  owner: string
  address?: {
    country: string
    state: string
    complement: string
    city: string
  }
  metrics: {
    dayOfWeekHoliday: number
    payByNaturalDays: boolean
    minSalary: number
    useChildren: boolean
    useHoliday: boolean
    usePunch: boolean
    ips: boolean
    fullNight: boolean
    extraConsider: boolean
    incents: boolean
    nightConsider: boolean
    night: number
    total: number
    hours: IHoursMetrics

    extra: {
      morning: number
      night: number
      morningHoliday: number
      nightHoliday: number
    }
  }
  ips: {
    day: boolean
    absence: boolean
    hour: {
      night: boolean
      extra: {
        morning: boolean
        night: boolean
        holidayMorning: boolean
        holidayNight: boolean
      }
    }
    other: {
      children: boolean
      mobility: boolean
      commission: boolean
      bonus: boolean
      gift: boolean
      other: boolean
    }

    discount: {
      day: boolean
      night: boolean
      strange: boolean
      other: boolean
    }
  }
  currency: {
    language: string
    currency: string
  }
  allowedPunch: IUserAllowedPunch

  companyAllowedPunch: ICompanyAllowedPunch

  places?: {
    name: string
    lat: string
    lng: string
    radius: number
  }[]

  holidays: [
    {
      name: string
      date: Date
      repeat?: boolean
    },
  ]

  deleted: boolean
  active: boolean
}

export interface IPaymentConfigGroup {
  id: string
  company_id: string
  name: string
  currency: string
  is_subscribed_ips: boolean
  should_include_ordinary_salary_in_night_hours: boolean
  hours_night_ordinary_value: number
  hours_night_extra_value: number
  hours_night_holiday_value: number
  hours_morning_ordinary_value: number
  hours_morning_extra_value: number
  hours_morning_holiday_value: number
  number_hours_per_day: number
  is_daily_payment: boolean
  should_consider_company_holidays: boolean
  should_consider_presence_default: boolean
  should_pay_children_bonus: boolean
  should_pay_as_natural_days: boolean
  should_include_night_ordinary_in_ips: boolean
  should_include_night_extra_in_ips: boolean
  should_include_morning_extra_in_ips: boolean
  should_include_night_holiday_in_ips: boolean
  should_include_morning_holiday_in_ips: boolean
  should_include_morning_hours_discount_in_ips: boolean
  should_include_night_hours_discount_in_ips: boolean
  should_include_children_bonus_in_ips: boolean
  should_include_payments_in_ips: boolean
  should_consider_31_days_month_partially_natural: boolean
  should_pay_hourly: boolean

  // authorizations
  should_authorize_night_hours: boolean
  should_authorize_extra_morning_hours: boolean
  should_authorize_extra_night_hours: boolean
  should_authorize_holiday_morning_hours: boolean
  should_authorize_holiday_night_hours: boolean
  should_authorize_morning_hours_discount: boolean
  should_authorize_night_hours_discount: boolean

  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

export interface IPunchConfigGroupRaw {
  id: string
  company_id: string
  name: string
  is_able_to_punch_geolocation: boolean
  is_able_to_punch_geolocation_face_id: boolean
  is_able_to_punch_face_id: boolean
  is_able_to_punch_offline: boolean
  is_presence_default: boolean
  should_use_liveness: boolean
  is_able_to_punch_from_anywhere: boolean
  is_able_to_see_punch_history: boolean
  should_show_invalid_geo_punches: boolean
  should_allow_facial_registration: boolean
  should_allow_unique_facial_registration: boolean
  required_liveness_precision: number
  required_aws_precision: number
  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

interface ICompanyConfigPlacesWithConfig {
  id: string
  company_place_id: string
  punch_config_group_id: string

  configGroup: IPunchConfigGroupRaw
  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

export type ICompanyPlace = {
  id: string
  company_id: string
  name: string
  description: string
  latitude: string
  longitude: string
  punches_radius: number
  created_at: Date
  updated_at: Date
  deleted_at: Date | null

  companyConfigPlaces: ICompanyConfigPlacesWithConfig[]
}
interface ICompanyConfigPlaces {
  id: string
  company_place_id: string
  punch_config_group_id: string

  companyPlace: ICompanyPlace
  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

export interface IPunchConfigGroup extends IPunchConfigGroupRaw {
  companyConfigPlaces: ICompanyConfigPlaces[]
}

export interface IUserCompany {
  id: string
  company_id: string
  user_id: string
  hiring_date: Date
  ips_registration_date: Date
  created_at: Date
  updated_at: Date
  deleted_at: Date | null
}

export interface ISalaryValue {
  id: string
  value: number
  is_minimum_wage: boolean
  start_date: string
  end_date: string
}

export interface ISalaryUser {
  id: string
  user_id: string
  value: number
}

export interface ISalaryGroupConcept {
  id: string
  name: string
  value: number
  ips: boolean
}

export interface ISalaryGroupHistoricalValue {
  id: string
  value: number
  is_minimum_wage: boolean

  start_date: string | null
  end_date: string | null

  concepts: Array<{
    id: string
    name: string
    value: number
    ips: boolean | null
    description: string | null
  }>
}

export interface ISalaryGroup {
  id: string
  type: string | null
  name: string
  description: string | null

  values: ISalaryGroupHistoricalValue[]

  // currentSalary: ISalaryValue
  // currentMinimumWageSalary: ISalaryValue
  // salaryValues: ISalaryValue[]
  // concepts: ISalaryGroupConcept[]
  // users: ISalaryUser[]
}

export interface IHoliday {
  id: string
  company_id: string
  name: string
  date: string
  should_repeat_every_year: boolean
  active?: boolean
  day_of_week?: number

  created_at: string
  updated_at: string
  deleted_at?: string
}

export interface ICompanyTotem {
  name: string
  id: string
  login: string
  password: string
  is_facial_registration_enabled: boolean
  is_location_obligatory: boolean
  is_active_face_id_disabled: boolean
  is_touch_face_id_disabled: boolean
  company_id: string
  created_at: string
}

export interface IVacationConfigGroupConcept {
  id: string
  name: string
}

export interface IVacationConfigGroup {
  id: string
  name: string
  concepts: IVacationConfigGroupConcept[]
  include_base_salary: boolean
  should_use_ips_date: boolean
  created_at: string
}

export interface IAguinaldoConfigGroupConcept {
  id: string
  name: string
}

export interface ILiquidationConfigGroupConcept {
  id: string
  name: string
}

export interface ILiquidationConfigBasicConcept {
  id: string
  name: string
  chosenName: string
  ips: boolean
}

export interface ILiquidationConfigOtherConcept {
  id: string
  name: string
  amount: number
  value: number
  ips: boolean
  isDebit: boolean
}

export interface ILiquidationConfigGroup {
  id: string
  name: string
  should_use_ips_date: boolean
  should_use_average_salary_in_vacations: boolean
  should_use_average_salary_in_proportional_salary: boolean
  vacation_value: boolean

  concepts: ILiquidationConfigGroupConcept[]
  basicConcepts: ILiquidationConfigBasicConcept[]
  otherConcepts: ILiquidationConfigOtherConcept[]

  ordinaryNightHours: boolean

  extraNightHours: boolean
  extraMorningHours: boolean

  holidayMorningHours: boolean
  holidayNightHours: boolean

  discountMorningHours: boolean
  discountNightHours: boolean

  familiarBonus: boolean
  mobility: boolean
  bonus: boolean
  commission: boolean
  gift: boolean
  otherEarns: boolean

  ips9: boolean
  antecipate: boolean
  loan: boolean
  lost: boolean
  purchase: boolean
  judicial: boolean
  excess: boolean
  otherDiscount: boolean

  created_at: string
}

export interface IAguinaldoConfigGroup {
  id: string
  name: string
  concepts: IAguinaldoConfigGroupConcept[]
  vacation_value: boolean
  proportional_vacations: boolean

  ordinaryNightHours: boolean

  extraNightHours: boolean
  extraMorningHours: boolean

  holidayMorningHours: boolean
  holidayNightHours: boolean

  discountMorningHours: boolean
  discountNightHours: boolean

  familiarBonus: boolean
  mobility: boolean
  bonus: boolean
  commission: boolean
  gift: boolean
  otherEarns: boolean

  ips9: boolean
  antecipate: boolean
  loan: boolean
  lost: boolean
  purchase: boolean
  judicial: boolean
  excess: boolean
  otherDiscount: boolean

  created_at: string
}

export interface ICompanyV2 {
  id: string
  bussiness_name: string
  name: string
  document: string
  phone: string
  email: string
  legal_representative: string
  IPS_patronal_number: string
  MTSS_patronal_number: string
  created_at: Date
  updated_at: Date
  deleted_at: Date | null

  companyPictureUrl: string | null

  activeEmployees?: number
  inactiveEmployees?: number
  totalEmployees?: number

  plan: PlanType

  punchConfigGroups: IPunchConfigGroup[]
  paymentConfigGroups: IPaymentConfigGroup[]
  vacationConfigGroups: IVacationConfigGroup[]
  aguinaldoConfigGroups: IAguinaldoConfigGroup[]
  liquidationConfigGroups: ILiquidationConfigGroup[]
  users: IUserCompany[]
  companyPlaces: ICompanyPlace[]
  salaryGroups: ISalaryGroup[]
  holidays: IHoliday[]
  totems: ICompanyTotem[]
  concepts: string[]
  liquidationConcepts: {
    id: string
    name: string
  }[]
}

export type BusinessCompanyInfoStatus =
  | 'CLIENT'
  | 'CANCELED'
  | 'IMPLEMENTING'
  | 'NEW'
  | 'DELETED'
  | 'TEST'

export type CompanyBusinessInfo = {
  id: string
  company_id: string
  status: BusinessCompanyInfoStatus
  default_month_invoice_value: number | null
  default_month_invoice_quantity: number | null
  default_external_product_code: string | null
  default_is_yearly_plan: boolean
  test_period_start: string | null
  test_period_end: string | null
  payroll_import_date: string | null
  app_setup_date: string | null
  face_register_date: string | null
  punch_register_date: string | null
  entrance_date: string | null
  cancelation_date: string | null
  email: string | null
  phone: string | null
  contact_name: string | null
  payment_month_day: number | null
  created_at: string
  updated_at: string
  updated_by: string | null
}

export interface ICompanyBusiness extends ICompanyV2 {
  businessInfos: CompanyBusinessInfo | null
}

export type InvoicePaymentStatus =
  | 'PENDING'
  | 'INVOICED'
  | 'FIRST_PAYMENT'
  | 'PAID'
  | 'CANCELED'

export type MonthlyInvoiceInformation = {
  id: string
  company_id: string
  month: number
  year: number
  plan_in_month: string | null
  is_yearly_plan: boolean
  month_invoice_value: number
  month_invoice_quantity: number
  external_product_code: string | null
  payment_status: InvoicePaymentStatus | null
  payment_automatic_status: InvoicePaymentStatus | null
  invoice_id: string | null
  company_business_info_id: string
  created_at: string
  updated_at: string
  updated_by: string | null
}

export interface ICompanyBusinessWithInvoice extends ICompanyBusiness {
  invoice: MonthlyInvoiceInformation | null
}

export interface ICompanyBusinessWithStatistics extends ICompanyBusiness {
  weeksPunches: number[] // done
  numberOfPayrolls: number // done
  activeWorkers: number
  totalPunches: number // done
  totalMovements: number // done
  totalVacations: number // done
  totalPayments: number // done
}

export interface ICompanyBankAccount {
  id: string
  company_id: string
  bank_name: string | null
  bank_account_number: string | null
  bank_account_type: string | null
}

export type IPaginatedCompanies = IDefaultApiPagination<ICompany>
export type ICompanyContextProps = IDefaultModuleContextProps
